import { useAuth0 } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';
import spinner from '../../assets/wired-gradient-105-loader-1 (1)-min.gif';

const Protected = () => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <img
        src={spinner}
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
        }}
        width='400'
        height='400'
      />
    );
  } else if (isAuthenticated) {
    return <Outlet />;
  } else if (!isAuthenticated && !isLoading) {
    loginWithRedirect();
    return (
      <div className='modal show'>
        <img
          src={spinner}
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
          }}
          width='400'
          height='400'
        />
      </div>
    );
  }
};

export default Protected;
