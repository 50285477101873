import { Link } from 'react-router-dom';
import Button from '../components/shared/Button';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

const NotFound = () => {
  return (
    <motion.div
      initial={{ opacity: '0' }}
      animate={{ opacity: '1' }}
      className='position-relative overflow-hidden text-center py-0 my-0'
      style={{ height: 'calc(100dvh - 98.8px)', width: '100dvw' }}
    >
      <Helmet>
        <title>Page not found | Allowance Track</title>
        <meta name='title' content='404 | Allowance Track'></meta>
        <meta name='description' content='Page not found!'></meta>
        <meta name='keywords' content=''></meta>
        <meta property='og:title' content='404 | Allowance Track' />
        <meta property='og:description' content='Page not found!'></meta>
        <meta property='og:type' content='website' />
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_BASE_URL}${window.location.pathname}`}
        />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content='404 | Allowance Track' />
        <meta name='twitter:description' content='Page Not Found' />
      </Helmet>
      <div
        style={{
          zIndex: '3',
          position: 'relative',
          width: '100dvw',
          height: 'calc(100dvh - 98.8px)',
          WebkitBackdropFilter: 'blur(20px)',
          backdropFilter: 'blur(20px)',
        }}
      >
        <div
          className='p-5 card'
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            msTransform: 'translate(-50%, -50%)',
            transform: 'translate(-50%, -50%)',
            width: '95dvw',
          }}
          id='not-found'
        >
          <div className='flowing-text mt-3'>404</div>
          <h3 className='display-5'>Oops... Page Not Found</h3>
          <Button
            className='my-5 not-found-btn mx-md-auto'
            color='primary'
            size='lg'
            As={Link}
            to='/'
          >
            Take me back
          </Button>
        </div>
      </div>
    </motion.div>
  );
};
export default NotFound;
