import { useContext, useEffect } from 'react';
import Button from '../shared/Button';
import UserDropItem from './UserDropItem';
import { AllowanceContext } from '../../context/AllowanceContext';
import Loader from '../shared/Loader';
import { useAuth0 } from '@auth0/auth0-react';
const UserDropDown = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const {
    kids: { list: kids, active },
    addKid,
    user: ctxuser,
    createModal,
  } = useContext(AllowanceContext);

  const kidName =
    ctxuser && ctxuser.plan == 'free'
      ? 'Add Kid'
      : active && active.kidName
      ? active.kidName
      : '--';
  useEffect(() => {
    if (
      typeof kids == 'object' &&
      !(kids[0] && kids[0].kidName && active && active.kidName) &&
      isAuthenticated &&
      !isLoading &&
      ctxuser
    ) {
      (async () => {
        addKid('Default Kid');
      })();
    }
  }, [kids, active, isAuthenticated, isLoading, ctxuser]);
  return (
    <div className='btn-group'>
      <Button
        className={`mb-0 text-decoration-none text-${
          kidName !== 'Add Kid' ? 'secondary' : 'success'
        } bg-white`}
        data-bs-toggle={kidName == 'Add Kid' ? 'modal' : 'dropdown'}
        data-bs-target={kidName == 'Add Kid' ? '#modal' : '#dropdown-kids'}
        onClick={() =>
          kidName == 'Add Kid' &&
          createModal(
            'Subscribe to add kids!',
            'PRICING',
            'PLAN_REQUIRED',
            false
          )
        }
        color='link'
        style={{
          minWidth: '100px',
          textOverflow: 'ellipsis',
          border: '1px solid #dee2e6',
          borderRadius: '0.4rem',
          display: 'flex',
          alignItems: 'space-between',
          justifyContent: 'space-between',
        }}
        aria-expanded='false'
      >
        <span
          style={{
            width: '80px',
            maxHeight: '20px',
            textOverflow: 'ellipsis',
            overflow: 'clip',
          }}
        >
          {kidName}
        </span>
        <span className='vert-cont mx-2 mr-0'>
          <i
            className={`fas fa-${kidName == 'Add Kid' ? 'plus' : 'caret-down'}`}
          ></i>
        </span>
      </Button>
      <ul className='dropdown-menu dropdown-menu-end shadow' id='dropdown-kids'>
        {typeof kids == 'object' && kids[0] && kids[0].kidName ? (
          kids.map(({ kidName: kidName, id, tempKidId, active, loading }) => (
            <UserDropItem
              key={tempKidId ? tempKidId.toString() : id}
              kidName={kidName}
              id={id}
              active={active}
              loading={loading}
            />
          ))
        ) : (
          <Loader size='250' />
        )}
        <UserDropItem addProfile />
      </ul>
    </div>
  );
};
export default UserDropDown;
