import { createContext, useReducer, useEffect } from 'react';
import AllowanceReducer from './AllowanceReducer';
import axios from 'axios';
import { DateObject } from 'react-multi-date-picker';
import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
import Button from '../components/shared/Button';
import Loader from '../components/shared/Loader';
import { Link, redirect, useRouteLoaderData } from 'react-router-dom';

export const AllowanceContext = createContext();

const AllowanceProvider = ({ children, db }) => {
  const {
    isAuthenticated,
    isLoading: auth0Loading,
    user: auth0user,
    user,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = { ...useAuth0() };

  const getWeekStartAndEndDates = (date, startIndex = 1, endIndex = 0) => {
    let startDate = new DateObject({ date });
    let endDate = new DateObject({ date });

    // Set the start date to the beginning of the week (Sunday)
    startDate = startDate.subtract(startDate.weekDay.index - 1, 'day');

    // Set the end date to the end of the week (Saturday)
    endDate = endDate.add(7 - endDate.weekDay.index, 'day');

    // Format dates as YYYY-MM-DD
    const str = startDate.format('YYYY-MM-DD');
    const formattedEndDate = endDate.format('YYYY-MM-DD');

    return {
      val: [new DateObject(startDate), new DateObject(endDate)],
      str,
    };
  };

  const initialState = {
    tasks: [],
    week: getWeekStartAndEndDates(
      new DateObject().subtract(1, 'day').format('YYYY-MM-DD')
    ),
    prevWeek: getWeekStartAndEndDates(
      new DateObject().subtract(1, 'day').format('YYYY-MM-DD')
    ).str,
    earnable: 5,
    earnableValue: '',
    percentCompleted: '100.00',
    earned: 99,
    checkedBoxes: 0,
    checkValue: 0,
    checks: 0,
    loading: true,
    alert: {
      msg: '',
      type: 'danger',
      hidden: true,
    },
    modal: {
      title: '',
      body: (
        <p
          className='mb-0'
          style={{
            textAlign: 'left',
          }}
        ></p>
      ),
      buttons: (
        <>
          <Button
            color='danger'
            onClick={() => {
              hideModal();
            }}
          >
            Continue logged out
          </Button>
          <Button
            color='success'
            onClick={() => {
              hideModal();
              loginWithRedirect();
            }}
          >
            Sign Up
          </Button>
        </>
      ),
      hidden: true,
      persistant: false,
    },
    overlay: false,
    user: null,
    tokenExpired: false,
    showNavBar: true,
    kids: {
      list: [{ kidName: 'Default Kid', id: 1, active: true, loading: true }],
      active: {
        kidName: '--',
        id: 1,
        active: true,
        loading: true,
      },
    },
    tasksLoaded: false,
    billing: localStorage.getItem('billing'),
    code: undefined,
  };

  const [state, dispatch] = useReducer(AllowanceReducer, initialState);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const showNav = () => dispatch({ type: 'SHOW_NAV' });
  const hideNav = () => dispatch({ type: 'HIDE_NAV' });
  const can = (canIDoThis, shouldIReturn = false, errorMessage = false) => {
    try {
      const thingITried = canIDoThis();
      return shouldIReturn ? thingITried : true;
    } catch {
      return errorMessage;
    }
  };

  useEffect(() => {
    if (!isAuthenticated && !auth0Loading) {
      const lstasks = localStorage.getItem('tasks');
      const perweek = localStorage.getItem('perWeek');

      dispatch({
        type: 'TASKS_LOADED',
        payload: {
          tasks: lstasks ? JSON.parse(lstasks) : [],
          earnable: perweek ? perweek : 5,
        },
      });
      setEarnableValue(perweek ? perweek : 5);
    }
  }, [isAuthenticated, auth0Loading]);

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const getAccessToken = async (params) => {
    if (isAuthenticated && !auth0Loading) {
      let token;
      try {
        token = await getAccessTokenSilently(
          params
            ? params == 'management_api'
              ? {
                  authorizationParams: {
                    audience: `${process.env.REACT_APP_AUTH0_URL}/api/v2`,
                  },
                }
              : params
            : {
                authorizationParams: {
                  audience: `https://api.allowancetrack.com`,
                  scope: 'openid profile email offline_access',
                },
              }
        );
      } catch (error) {
        dispatch({ type: 'TOKEN_EXPIRED', isAuthenticated });
        console.error(error);
        console.error('notoken1');
        loginWithRedirect();

        return null;
      }
      if (token) {
        // get the expiration time from the token
        const decoded = jwt_decode(token, { header: true });
        const expirationTime = decoded.exp * 1000; // convert to milliseconds

        // get the current time
        const currentTime = new Date().getTime();

        // check if the token is expired
        if (currentTime > expirationTime) {
          // token is expired, log the user out
          dispatch({ type: 'TOKEN_EXPIRED', isAuthenticated });
          console.error('notoken2');
          return null;
        } else {
          if (!token) {
            console.error('notoken3');
            return;
          }

          return token;
        }
      } else {
        dispatch({ type: 'TOKEN_EXPIRED', isAuthenticated });
        console.error('notoken4');
      }
    } else {
      console.log(
        'isAuthenticated',
        isAuthenticated,
        'isLoading',
        auth0Loading
      );
      console.error('notoken5');
      return null;
    }
  };

  const checkout = async (monthly = false) => {
    const token = await getAccessToken();
    setLoading(true);
    if (token) {
      const encodedUserId = btoa(auth0user.sub);
      const res = await api.post(
        '/create-checkout-session',
        {
          monthly,
          encodedUserId,
        },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      const url = res.data.url;
      window.location.href = url;
    } else {
      return;
    }
  };

  const portal = async () => {
    setLoading(true);
    const token = await getAccessToken();
    if (token) {
      const encodedUserId = btoa(auth0user.sub);
      const res = await api.post(
        '/create-portal-session',
        {
          encodedUserId,
        },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      const url = res.data.url;
      window.location.href = url;
    } else {
      return;
    }
  };

  const fetchTasksFromDB = async (week, currWeek) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true, isAuthenticated });

      const t1 = await getAccessToken();
      let response;
      if (t1) {
        response = await api.get(
          `/users/${encodeURIComponent(
            state.user ? state.user.sub : auth0user.sub
          )}${
            state.kids.active &&
            state.kids.active.id &&
            !state.kids.active.loading
              ? `/children/${state.kids.active.id}`
              : ''
          }/tasks/${week}/${currWeek ? currWeek : 'na'}`,
          {
            headers: { authorization: `Bearer ${t1}` },
          }
        );
      } else {
        return;
      }
      let earnable;
      await (async () => {
        try {
          const t2 = await getAccessToken();
          let earnableRes;

          if (t2) {
            earnableRes = await api.get(
              `/users/${encodeURIComponent(
                state.user ? state.user.sub : auth0user.sub
              )}${
                state.kids.active &&
                state.kids.active.id &&
                !state.kids.active.loading
                  ? `/children/${state.kids.active.id}`
                  : ''
              }/stats/${week}/${currWeek ? currWeek : 'na'}`,
              {
                headers: { authorization: `Bearer ${t2}` },
              }
            );
          } else {
            return;
          }

          earnable = new Number(earnableRes.data.earnable);
        } catch (error) {
          console.error(error);
        }
      })();
      let tasks = response.data.map((task, i) => {
        return {
          ...task,
          task: task.taskName,
        };
      });

      setEarnableValue(earnable);

      dispatch({
        type: 'TASKS_LOADED',
        payload: { tasks, earnable: earnable ? earnable : 5 },
        isAuthenticated,
      });

      if (week == 'prev') {
        clearChecks();
      }

      return tasks;
    } catch (error) {
      console.error(error);
    }
  };

  const sleep = (time, debug = false) => {
    debug && console.log(`Taking a ${time} millisecond nap`);
    return new Promise((res) =>
      setTimeout(
        () => {
          debug && console.log(`Done napping`);
          res();
        },
        time,
        'done sleeping'
      )
    );
  };

  const setWeek = (payload) => {
    dispatch({ type: 'SET_WEEK', payload, isAuthenticated });
  };

  const setPrevWeek = (payload) => {
    dispatch({ type: 'SET_PREV_WEEK', payload, isAuthenticated });
  };

  const setEarnableValue = (payload) => {
    dispatch({ type: 'SET_EARNABLE_VALUE', payload });
  };

  const createAlert = (msg, type, timeout = 4000) => {
    dispatch({ type: 'CREATE_ALERT', payload: { msg, type }, isAuthenticated });
    setTimeout(
      () => dispatch({ type: 'REMOVE_ALERT', isAuthenticated }),
      timeout
    );
  };

  const removeAlert = () => {
    dispatch({ type: 'REMOVE_ALERT', isAuthenticated });
  };

  const createModal = (title, body, buttonStyle, persistant, scroll) => {
    let buttons =
      buttonStyle == 'ACCOUNT_REQUIRED' ? (
        <>
          <Button
            outline
            color='primary'
            onClick={() => {
              loginWithRedirect();
            }}
          >
            Log In
          </Button>
          <Button
            color='success'
            className='bg-animated-b-g'
            onClick={() => {
              createModal(
                'Choose a plan!',
                'PRICING',
                <>
                  <Button
                    outline
                    color='primary'
                    onClick={() => {
                      loginWithRedirect({
                        authorizationParams: {
                          screen_hint: 'signup',
                          redirect_uri: `${baseUrl}`,
                        },
                      });
                    }}
                  >
                    Free Plan
                  </Button>
                  <Button
                    color='success'
                    className='bg-animated-b-g'
                    onClick={() => {
                      loginWithRedirect({
                        authorizationParams: {
                          screen_hint: 'signup',
                          redirect_uri: `${baseUrl}/billing`,
                        },
                      });
                    }}
                  >
                    Plus Plan
                  </Button>
                </>,
                false
              );
            }}
          >
            Sign Up
          </Button>
        </>
      ) : buttonStyle == 'PLAN_REQUIRED' ? (
        <>
          <Button
            outline
            color='secondary'
            data-bs-toggle='modal'
            data-bs-target='#modal'
          >
            Cancel
          </Button>
          <Button
            color='success'
            className='bg-animated-b-g'
            As={Link}
            to='/billing'
          >
            Upgrade
          </Button>
        </>
      ) : (
        buttonStyle
      );
    dispatch({
      type: 'CREATE_MODAL',
      payload: {
        title,
        body:
          body == 'PRICING' ? (
            <>
              <div
                className='table-responsive'
                style={{
                  position: 'relative',
                  height: 'calc(80vh - 150px)',
                }}
              >
                <table className='table text-center table-hover-custom'>
                  <thead
                    className='rounded bg-primary'
                    style={{
                      position: 'sticky',
                      top: 0,
                      left: 0,
                      right: 0,
                      borderRadius: '0.375rem',
                      color: 'white',
                      zIndex: '500000000',
                    }}
                  >
                    <tr
                      style={{
                        borderRadius: '0.375rem',
                        color: 'white',
                        zIndex: '500000000',
                      }}
                    >
                      <th
                        className='!bg-primary not'
                        style={{
                          width: '50%',
                          borderTopLeftRadius: '0.375rem',
                          borderBottomLeftRadius: '0.375rem',
                          color: 'white',
                          zIndex: '500000000',
                        }}
                      ></th>
                      <th
                        className='!bg-primary not'
                        style={{
                          width: '25%',
                          color: 'white',
                          zIndex: '500000000',
                        }}
                      >
                        Free
                      </th>
                      <th
                        className='!bg-primary not'
                        style={{
                          width: '25%',
                          borderTopRightRadius: '0.375rem',
                          borderBottomRightRadius: '0.375rem',
                          color: 'white',
                          zIndex: '500000000',
                        }}
                      >
                        Plus
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope='row' className='text-start not'>
                        Price
                      </th>
                      <th className='vert-cont not'>Free</th>
                      <th className='vert-cont not'>$5/Month</th>
                    </tr>

                    <tr>
                      <th scope='row' className='text-start not'>
                        Multiple Kids
                        <p style={{ fontWeight: 'normal' }} className='not'>
                          Track allowances and chores for each of your kids -
                          all in one account
                        </p>
                      </th>
                      <td className='vert-cont'>
                        <i className='fas fa-xmark vert-target text-danger'></i>
                      </td>
                      <td className='vert-cont'>
                        <i className='fas fa-check vert-target text-success'></i>
                      </td>
                    </tr>

                    <tr>
                      <th scope='row' className='text-start not'>
                        Multiple Weeks
                        <p style={{ fontWeight: 'normal' }} className='not'>
                          Have different chore history for multiple weeks and
                          easily view the chores anytime
                        </p>
                      </th>
                      <td className='vert-cont'>
                        <i className='fas fa-xmark vert-target text-danger'></i>
                      </td>
                      <td className='vert-cont'>
                        <i className='fas fa-check vert-target text-success'></i>
                      </td>
                    </tr>
                    <tr>
                      <th scope='row' className='text-start not'>
                        Maximum Chores
                        <p style={{ fontWeight: 'normal' }} className='not'>
                          Maximum number of chores you can add (per week)
                        </p>
                      </th>

                      <td className='vert-cont'>
                        <span className='vert-target text-danger'>6</span>
                      </td>
                      <td className='vert-cont'>
                        <span className='vert-target text-success'>
                          Unlimited
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th scope='row' className='text-start not'>
                        Ad-free
                        <p style={{ fontWeight: 'normal' }} className='not'>
                          Enjoy Allowance Track with no ads!
                        </p>
                      </th>
                      <td className='vert-cont'>
                        <i className='fas fa-xmark vert-target text-danger'></i>
                      </td>
                      <td className='vert-cont'>
                        <i className='fas fa-check vert-target text-success'></i>
                      </td>
                    </tr>
                    <tr>
                      <th scope='row' className='text-start not'>
                        Cloud Syncing
                        <p style={{ fontWeight: 'normal' }} className='not'>
                          Store your chores in the cloud to sync across devices
                        </p>
                      </th>
                      <td className='vert-cont'>
                        <i className='fas fa-check vert-target text-success'></i>
                      </td>
                      <td className='vert-cont'>
                        <i className='fas fa-check vert-target text-success'></i>
                      </td>
                    </tr>
                    <tr>
                      <th scope='row' className='text-start not'>
                        Days Required
                        <p style={{ fontWeight: 'normal' }} className='not'>
                          Specify specific days of the week that a chore is
                          required
                        </p>
                      </th>

                      <td className='vert-cont'>
                        <i className='fas fa-check vert-target text-success'></i>
                      </td>
                      <td className='vert-cont'>
                        <i className='fas fa-check vert-target text-success'></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            body
          ),
        buttons,
        persistant,
        hidden: false,
        scroll,
      },
      isAuthenticated,
    });
  };

  const hideModal = () => {
    dispatch({ type: 'REMOVE_MODAL' });
  };

  const addTask = async (taskName, requiredDays) => {
    // Required days is an array of true/false
    try {
      if (!auth0Loading) {
        if (isAuthenticated) {
          if (
            state.user &&
            state.user.plan == 'free' &&
            state.tasks.length >= 6
          ) {
            setOverlay('modal');
            createModal(
              'Upgrade to add more tasks',
              'PRICING',
              'PLAN_REQUIRED',
              false
            );
            return;
          }

          // Update the checkStates array according to the requiredDays
          const checkStates = requiredDays.map((isDayRequired, index) =>
            isDayRequired ? false : null
          );

          const newTask = {
            task: taskName,
            checkStates: checkStates,
          };
          const tempTask = {
            task: taskName,
            loading: true,
            id: Symbol('tempid' + new Date()),
          };

          dispatch({ type: 'NEW_TASK', payload: tempTask, isAuthenticated });
          const token = await getAccessToken();
          let response;
          if (token && auth0user) {
            response = await api.post(
              `/users/${encodeURIComponent(auth0user.sub)}${
                state.kids.active &&
                state.kids.active.id &&
                !state.kids.active.loading
                  ? `/children/${state.kids.active.id}`
                  : ''
              }/tasks`,
              {
                taskName,
                week: state.week.str,
                checkStates: checkStates, // Pass the updated checkStates to the server
              },
              {
                headers: { authorization: `Bearer ${token}` },
              }
            );
            dispatch({
              type: 'FINISH_NEW_TASK',
              payload: {
                tempId: tempTask.id,
                task: {
                  ...newTask,
                  ...response.data,
                  id: response.data.taskId,
                  loading: false,
                },
              },
              isAuthenticated,
            });

            return response.data.taskId;
          } else {
            dispatch({
              type: 'REMOVE_TASK',
              payload: tempTask.id,
              isAuthenticated,
            });
            return -1;
          }
        } else {
          // Update the checkStates array according to the requiredDays
          if (state.tasks.length >= 2) {
            setOverlay('modal');
            createModal(
              'Sign up to add more tasks!',
              <>
                <p className='my-0' style={{ textAlign: 'left' }}>
                  You have reached your maximum number of tasks! Sign up to get
                  more!
                </p>
              </>,
              'ACCOUNT_REQUIRED'
            );
            return;
          }

          const checkStates = requiredDays.map((isDayRequired, index) =>
            isDayRequired ? false : null
          );

          const newTask = {
            task: taskName,
            checkStates: checkStates,
            id: state.tasks.length,
          };
          dispatch({ type: 'NEW_TASK', payload: newTask, isAuthenticated });
        }
      }
      // setTimeout(() => , 500);
    } catch (error) {
      console.error(error);
    }
  };

  const updateChecked = async (checkStates, id) => {
    dispatch({
      type: 'UPD_CHECKS',
      payload: { checkStates, id },
      isAuthenticated,
    });
    const token = isAuthenticated && !auth0Loading && (await getAccessToken());

    if (token) {
      const res = await api.put(
        `/tasks/${id}/weeks/${state.week.str}`,
        {
          checkStates,
        },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
    } else {
      return;
    }
  };

  const changeWeek = (mode, num, type) => {
    const str = new DateObject({
      date: state.week.str,
      format: 'YYYY-MM-DD',
    })
      [mode](num, type)
      .format('YYYY-MM-DD');
    const newWeek = getWeekStartAndEndDates(new Date(str));
    setWeek(newWeek);

    isAuthenticated && !auth0Loading && fetchTasksFromDB(str);
  };

  const updateTask = async (reqDays, newName, id) => {
    try {
      // Update the checkStates array according to the reqDays
      let checkStates;

      state.tasks.map((task) =>
        task.id == id // : '')
          ? (checkStates = reqDays.map((isDayRequired, i) => {
              return isDayRequired
                ? task.checkStates[i] == null
                  ? false
                  : task.checkStates[i]
                : null;
            }))
          : ''
      );

      // Dispatch update task action with checkStates instead of reqDays
      dispatch({
        type: 'UPD_TASK',
        payload: { checkStates, newName, id },
        isAuthenticated,
      });

      // Update task name
      const t1 = isAuthenticated && !auth0Loading && (await getAccessToken());
      if (t1) {
        await api.put(
          `/users/${encodeURIComponent(state.user.sub)}/tasks/${id}`,
          {
            taskName: newName,
          },
          {
            headers: { authorization: `Bearer ${t1}` },
          }
        );
      } else {
        return;
      }

      // Update checkStates for a specific week
      const t2 = isAuthenticated && !auth0Loading && (await getAccessToken());

      if (t2) {
        await api.put(
          `/tasks/${id}/weeks/${state.week.str}`,
          {
            checkStates,
          },
          {
            headers: { authorization: `Bearer ${t2}` },
          }
        );
      } else {
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeTask = async (id) => {
    createModal(
      'Are you sure?',
      <p className='mb-0' style={{ textAlign: 'left' }}>
        Do you want to delete the task{' '}
        <span style={{ wordBreak: 'break-all' }}>
          {state.tasks.filter((task) => task.id == id)[0].task}
        </span>
        ? This action is irreversible.
      </p>,
      <>
        <Button
          color='danger'
          onClick={async () => {
            setOverlay(false);
            dispatch({ type: 'REMOVE_TASK', payload: id, isAuthenticated });

            const token =
              isAuthenticated && !auth0Loading && (await getAccessToken());
            if (token) {
              await api.delete(
                `/users/${encodeURIComponent(state.user.sub)}/tasks/${id}/${
                  state.week.str
                }`,
                {
                  data: {
                    week: state.week.str,
                  },
                  headers: { authorization: `Bearer ${token}` },
                }
              );
            } else {
              return;
            }
          }}
        >
          Confirm
        </Button>
        <Button
          color='secondary'
          onClick={() => {
            setOverlay(false);
          }}
        >
          Cancel
        </Button>
      </>
    );
    setOverlay('modal');
  };

  const setEarnable = async (newVal) => {
    dispatch({ type: 'SET_EARNABLE', payload: newVal, isAuthenticated });
    const token = isAuthenticated && !auth0Loading && (await getAccessToken());

    if (token) {
      let res1234;
      try {
        res1234 = await api.put(
          `/users/${encodeURIComponent(auth0user.sub)}${
            state.kids.active &&
            state.kids.active.id &&
            !state.kids.active.loading
              ? `/children/${state.kids.active.id}`
              : ''
          }/stats/${state.week.str}`,
          {
            earnable: newVal,
          },
          {
            headers: { authorization: `Bearer ${token}` },
          }
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      return;
    }
  };

  const clearChecks = async () => {
    dispatch({ type: 'CLEAR_CHECKS', isAuthenticated });
    const token = isAuthenticated && auth0Loading && (await getAccessToken());
    if (token) {
      state.tasks.map(async (task) => {
        await api.put(
          `/tasks/${task.id}/weeks/${state.week.str}`,
          {
            checkStates: task.checkStates.map((s) =>
              s == null ? null : false
            ),
          },
          {
            headers: { authorization: `Bearer ${token}` },
          }
        );
      });
    } else {
      return;
    }
  };

  const setUser = (payload) => {
    dispatch({ type: 'SET_USER', payload, isAuthenticated });
  };

  useEffect(() => {
    dispatch({ type: 'UPD_MATH', isAuthenticated });
  }, []);

  const setLoading = (newLoading) => {
    dispatch({ type: 'SET_LOADING', payload: newLoading, isAuthenticated });
  };

  // -------------------------------------------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  // ----------------------FIX NEEDED-----------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  // useEffect(() => {
  //   setInterval(async () => {
  //     await getAccessToken();
  //     ;
  //     setLoading(state.loading);
  //   }, 60000);
  // }, []);

  useEffect(() => {
    ((!auth0Loading && !isAuthenticated) || auth0Loading) &&
      setLoading(auth0Loading);
    if (!auth0Loading && isAuthenticated && auth0user !== undefined) {
      setLoading(true);
      (async () => {
        const token = await getAccessToken();
        if (token) {
          const res = await api.get(`/users/${auth0user.sub}/info`, {
            headers: { authorization: `Bearer ${token}` },
          });
          const plan = res.data.plan;
          const email_verified =
            res.data.email_verified === 1 &&
            res.data.email_verified !== undefined;
          console.log(
            '🎄🎄🎄🎄🎄🎄🎄🎄🎄🎄🎄res.data.email_verified',
            res.data.email_verified
          );
          if (plan) {
            console.log(
              '🎈🎆🎇🎇🧨✨✨🎉🎊🎃🎃🎄🎋🎍🎎🎎🎏🎐🎑🧧🎁🎁🎗🎞🎟🎫🎫',
              plan
            );
            setUser({ ...auth0user, plan, email_verified });
            setLoading(false);
          } else {
            setLoading(false);
            setUser({ ...auth0user, plan: 'free', email_verified });
          }
        }
      })();
    }
  }, [auth0Loading, isAuthenticated, auth0user]);

  const taskExists = (target) =>
    state.tasks.some(
      (taskObj) =>
        taskObj.task.trim().toLowerCase() === target.trim().toLowerCase()
    );
  const kidExists = (target) =>
    state.kids.list.some(
      (kidObj) =>
        kidObj.kidName.trim().toLowerCase() === target.trim().toLowerCase()
    );

  const setOverlay = (to) => {
    dispatch({ type: 'SET_OVERLAY', payload: to, isAuthenticated });
  };

  const setKids = (list) => dispatch({ type: 'SET_KIDS', payload: list });

  const setActiveKid = (id) =>
    dispatch({ type: 'SET_ACTIVE_KID', payload: id });

  const updateKid = async (kid) => {
    try {
      if (!auth0Loading) {
        if (isAuthenticated && state.user && state.user.plan !== 'free') {
          dispatch({ type: 'UPDATE_KID', payload: kid });
          const token = await getAccessToken();
          let response;
          if (token && auth0user) {
            response = await api.put(
              `/${encodeURIComponent(auth0user.sub)}/children/${kid.id}`,
              {
                name: kid.kidName,
              },
              {
                headers: { authorization: `Bearer ${token}` },
              }
            );
          }
        } else {
          // Update the checkStates array according to the requiredDays
          if (user && user.plan == 'free') {
            setOverlay('modal');
            createModal(
              'Upgrade to add kids!',
              <>
                <p className='my-0' style={{ textAlign: 'left' }}>
                  To have multiple kids in one account, you have to upgrade!
                </p>
              </>,
              'PLAN_REQUIRED'
            );
            return;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addKid = async (kidName) => {
    try {
      if (!auth0Loading) {
        if (
          isAuthenticated &&
          state.user &&
          state.user.plan !== 'free' &&
          state.kids.list.length <= 3
        ) {
          const tempKidId = Symbol(
            `TempKidNo.${
              state.kids.list[state.kids.list.length - 1]
                ? state.kids.list[state.kids.list.length - 1].id + 1
                : 1
            }`
          );

          dispatch({
            type: 'ADD_KID',
            payload: {
              tempKidId,
              kidName,
            },
          });
          const token = await getAccessToken();
          let response;
          if (token && auth0user) {
            response = await api.post(
              `/${encodeURIComponent(auth0user.sub)}/children`,
              {
                kidName,
              },
              {
                headers: { authorization: `Bearer ${token}` },
              }
            );
            dispatch({
              type: 'FINISH_ADD_KID',
              payload: {
                tempKidId,
                id: response.data.childId,
              },
              isAuthenticated,
            });

            return response.data.childId;
          } else {
            dispatch({
              type: 'REMOVE_KID',
              payload: tempKidId,
              isAuthenticated,
            });
            return -1;
          }
        } else {
          // Update the checkStates array according to the requiredDays
          if (user && user.plan == 'free') {
            setOverlay('modal');
            createModal(
              'Sign up for Plus to add kids!',
              <>
                <p className='my-0' style={{ textAlign: 'left' }}>
                  To have multiple kids in one account, you have to sign up for
                  a paid account!
                </p>
              </>,
              'PLAN_REQUIRED'
            );
            return;
          } else if (state.kids.list.length <= 4) {
            createModal(
              "You can't add any more kids!",
              <>
                <p className='my-0' style={{ textAlign: 'left' }}>
                  You have reached your maximum number of kids.
                </p>
              </>,
              <Button
                color='secondary'
                data-bs-toggle='modal'
                data-bs-target='#modal'
              >
                Ok
              </Button>
            );
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeKid = async (id) => {
    try {
      createModal(
        'Are you sure you want to delete this kid?',
        <div>
          <p style={{ textAlign: 'left' }}>
            This will permanetly delete this child and all of their history.
            This action is irriversable.
          </p>
        </div>,
        <>
          <Button color='secondary' onClick={() => setOverlay(false)}>
            Cancel
          </Button>
          <Button
            color='danger'
            onClick={async () => {
              try {
                if (!auth0Loading) {
                  if (
                    isAuthenticated &&
                    state.user &&
                    state.user.plan !== 'free'
                  ) {
                    dispatch({ type: 'REMOVE_KID', payload: id });
                    const token = await getAccessToken();
                    let response;
                    if (token && auth0user) {
                      response = await api.delete(
                        `/${encodeURIComponent(auth0user.sub)}/children/${id}`,
                        {
                          headers: { authorization: `Bearer ${token}` },
                        }
                      );
                    }
                  } else {
                    // Update the checkStates array according to the requiredDays
                    if (user && user.plan == 'free') {
                      setOverlay('modal');
                      createModal(
                        'Upgrade to add kids!',
                        <>
                          <p className='my-0' style={{ textAlign: 'left' }}>
                            To have multiple kids in one account, you have to
                            upgrade!
                          </p>
                        </>,
                        'PLAN_REQUIRED'
                      );
                      return;
                    }
                  }
                }
                setOverlay(false);
              } catch (error) {
                console.error(error);
                setOverlay(false);
              }
            }}
          >
            Confirm
          </Button>
        </>
      );
      setOverlay('modal');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      if (isAuthenticated && !auth0Loading) {
        const token = await getAccessToken();
        if (token && auth0user) {
          const res = await api.get(`/${auth0user.sub}/children`, {
            headers: {
              authorization: `Bearer ${token}`,
            },
          });
          const newKids = res.data.map((o, i) => ({
            ...o,
            active: i == 0 ? true : false,
            loading: false,
            childId: undefined,
            id: o.childId,
            name: undefined,
            kidName: o.name,
            parentAuth0Id: undefined,
          }));
          setKids(newKids);
        }
      }
    })();
  }, [isAuthenticated, auth0Loading]);

  const resetPassword = async () => {
    try {
      createModal('Loading...', <Loader size={200} />);
      const token = await getAccessToken();
      if (token) {
        await api.put(
          `/users/${auth0user.sub}/password`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              authorization: `Bearer ${token}`,
            },
          }
        );
        createModal(
          'Email sent!',
          <p style={{ textAlign: 'left' }}>
            A password reset link was sent to{' '}
            <code className='bg-light rounded p-1'>{auth0user.email}</code>. You
            should recieve the email soon
          </p>,
          <Button
            color='secondary'
            data-bs-toggle='modal'
            data-bs-target='#modal'
          >
            Ok
          </Button>
        );
      } else {
        createModal(
          'There was an issue',
          <p style={{ textAlign: 'left' }}>
            A password reset link could not be sent to{' '}
            <code className='bg-light rounded p-1'>{auth0user.email}</code>.
          </p>,
          <Button
            color='secondary'
            data-bs-toggle='modal'
            data-bs-target='#modal'
          >
            Ok
          </Button>
        );
      }
    } catch (error) {
      createModal(
        'There was an issue',
        <p style={{ textAlign: 'left' }}>
          A password reset link could not be sent to{' '}
          <code className='bg-light rounded p-1'>{auth0user.email}</code>.
        </p>,
        <Button
          color='secondary'
          data-bs-toggle='modal'
          data-bs-target='#modal'
        >
          Ok
        </Button>
      );
    }
  };

  const reVerify = async () => {
    const token = await getAccessToken();
    let response;
    if (token && auth0user) {
      await api.post(
        '/reverify',
        {
          user_id: auth0user.sub,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            authorization: `Bearer ${token}`,
          },
          maxBodyLength: Infinity,
        }
      );
      createAlert('Verification Email Sent', 'success');
    } else {
      return;
    }
  };

  const fetchAdmin = async () => {
    try {
      const token = await getAccessToken();
      if (token) {
        const response = await api.get('/admin', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data;
      } else {
        return 'error';
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
      return 'error';
    }
  };

  const setBilling = (payload) => {
    dispatch({ type: 'SET_BILLING', payload });
    localStorage.setItem('billing', payload + '');
  };

  const removeBilling = () => {
    dispatch({ type: 'SET_BILLING', payload: false });
    localStorage.removeItem('billing');
  };

  const getVerificationCode = async () => {
    const token = await getAccessToken();
    if (token) {
      const res = await api.post(
        `/${auth0user.sub}/code`,
        { email: auth0user.email },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      const code = res.data.code;

      dispatch({ type: 'SET_CODE', payload: code });
    } else {
      return 'fail';
    }
  };

  console.log(
    'email verified 🎈🎆🎇🧨',
    state.user ? state.user.email_verified : 'No User'
  );

  const verifyEmail = async (enteredCode) => {
    setLoading(true);

    console.log(
      'typeof enteredCode == typeof state.code',
      typeof enteredCode == typeof state.code
    );
    const token = await getAccessToken();
    if (token && enteredCode == state.code) {
      setUser({ ...state.user, email_verified: true });
      await api.post(
        `/${auth0user.sub}/verifyEmail`,
        { email: auth0user.email },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);

      return 'success';
    } else {
      setLoading(false);
      createAlert('The code you entered was incorrect', 'danger');
      return 'fail';
    }
  };

  const getAllBlogPosts = async () => {
    try {
      const res = await api.get('/articles');
      if (res.status === 200) {
        return res.data;
      } else {
        console.error('Failed to fetch blog posts');
        return [];
      }
    } catch (error) {
      console.error('Error fetching blog posts:', error);
      return [];
    }
  };

  const getBlogPost = async (route) => {
    try {
      const res = await api.get(`/articles/${route}`);
      if (res.status === 200) {
        return res.data;
      } else {
        console.error('Failed to fetch blog post');
        return null;
      }
    } catch (error) {
      console.error('Error fetching blog post:', error);
      return null;
    }
  };

  return (
    <AllowanceContext.Provider
      value={{
        ...state,
        baseUrl,
        setEarnable,
        addTask,
        updateChecked,
        updateTask,
        removeTask,
        clearChecks,
        fetchTasksFromDB,
        sleep,
        createAlert,
        setLoading,
        taskExists,
        getWeekStartAndEndDates,
        setWeek,
        changeWeek,
        setPrevWeek,
        setEarnableValue,
        setOverlay,
        createModal,
        hideModal,
        checkout,
        portal,
        showNav,
        hideNav,
        setKids,
        setActiveKid,
        updateKid,
        addKid,
        removeKid,
        kidExists,
        resetPassword,
        getAccessToken,
        reVerify,
        can,
        fetchAdmin,
        setBilling,
        removeBilling,
        verifyEmail,
        getVerificationCode,
        getAllBlogPosts,
        getBlogPost,
        removeAlert,
        api,
      }}
    >
      {children}
    </AllowanceContext.Provider>
  );
};

export default AllowanceProvider;
