import { Gradient } from 'whatamesh';
import { useEffect } from 'react';

const gradient = new Gradient();

const WhatAMesh = () => {
  useEffect(() => {
    gradient.initGradient('#mesh-gradient');
  }, []);

  return (
    <canvas
      id='mesh-gradient'
      data-transition-in
      style={{
        width: '100dvw',
        height: 'calc(100dvh + 98.8px)',
        zIndex: '1',
      }}
    />
  );
};

export default WhatAMesh;
