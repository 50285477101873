import { useAuth0 } from '@auth0/auth0-react';
import Button from '../components/shared/Button';

const roundTo = (num, decimalPlaces, dir) => {
  const factor = Math.pow(10, decimalPlaces);
  return dir == 'down'
    ? Math.floor(num * factor) / factor
    : dir == 'up'
    ? Math.ceil(num * factor) / factor
    : factor;
};

const updMath = (state) => {
  let checkedBoxes = 0;
  let checks = 0;

  for (let task of state.tasks) {
    if (!task.loading)
      for (let state of task.checkStates) {
        if (state !== null) {
          if (state === true) checkedBoxes += 1;
          checks += 1;
        }
      }
  }

  const checkValue = Number(Number(state.earnable) / Number(checks));

  let earned = roundTo(Number(checkValue * checkedBoxes), 2, 'down').toFixed(2);
  earned = isNaN(earned) ? '0.00' : earned;

  const percentCompleted =
    earned == 0 || isNaN(earned)
      ? '0'
      : roundTo((earned / state.earnable) * 100, 0, 'up');

  return { checkValue, checks, checkedBoxes, earned, percentCompleted };
};

export default (state, { type, payload, isAuthenticated }) => {
  let tasks;
  let checkStates;

  switch (type) {
    case 'UPD_CHECKS':
      tasks = state.tasks.map((task) =>
        task.id !== payload.id
          ? task
          : { ...task, checkStates: payload.checkStates }
      );

      !isAuthenticated && localStorage.setItem('tasks', JSON.stringify(tasks));
      return { ...state, tasks, ...updMath({ ...state, tasks }) };
    case 'UPD_TASK':
      tasks = state.tasks.map((task) =>
        task.id !== payload.id
          ? task
          : { ...task, task: payload.newName, checkStates: payload.checkStates }
      );

      !isAuthenticated && localStorage.setItem('tasks', JSON.stringify(tasks));
      return { ...state, tasks, ...updMath({ ...state, tasks }) };
    case 'NEW_TASK':
      tasks = [...state.tasks, payload];

      !isAuthenticated && localStorage.setItem('tasks', JSON.stringify(tasks));
      return { ...state, tasks, ...updMath({ ...state, tasks }) };
    case 'FINISH_NEW_TASK':
      tasks = state.tasks.map((task) =>
        task.id !== payload.tempId ? task : payload.task
      );

      !isAuthenticated && localStorage.setItem('tasks', JSON.stringify(tasks));
      return { ...state, tasks, ...updMath({ ...state, tasks }) };
    case 'REMOVE_TASK':
      tasks = state.tasks.filter((task) => task.id !== payload);

      !isAuthenticated && localStorage.setItem('tasks', JSON.stringify(tasks));
      return { ...state, tasks, ...updMath({ ...state, tasks }) };
    case 'UPD_MATH':
      return {
        ...state,
        ...updMath(state),
      };
    case 'SET_EARNABLE':
      !isAuthenticated && localStorage.setItem('perWeek', payload);
      return {
        ...state,
        ...updMath({ ...state, earnable: payload }),
        earnable: payload,
      };
    case 'CLEAR_CHECKS':
      tasks = state.tasks.map((task) => {
        let checkStates = task.checkStates.map((s) =>
          s == null ? null : false
        );
        return { ...task, checkStates };
      });

      !isAuthenticated && localStorage.setItem('tasks', JSON.stringify(tasks));

      return { ...state, tasks, ...updMath({ ...state, tasks }) };
    case 'SET_LOADING':
      return { ...state, loading: payload };
    case 'SET_WEEK':
      return { ...state, week: payload };
    case 'SET_PREV_WEEK':
      return { ...state, prevWeek: payload };
    case 'SET_EARNABLE_VALUE':
      return { ...state, earnableValue: payload };
    case 'TOKEN_EXPIRED':
      return { ...state, tokenExpired: true };
    case 'SHOW_NAV':
      return { ...state, showNavBar: true };
    case 'HIDE_NAV':
      return { ...state, showNavBar: false };
    case 'TASKS_LOADED':
      return {
        ...state,
        tasks: payload.tasks ? payload.tasks : [],
        earnable: payload.earnable,
        loading: false,
        ...updMath({
          ...state,
          tasks: payload.tasks,
          earnable: payload.earnable,
        }),
        tasksLoaded: true,
      };
    case 'SET_TASKS':
      return {
        ...state,
        tasks: payload.tasks,
        ...updMath({
          ...state,
          tasks: payload.tasks,
        }),
      };
    case 'CREATE_ALERT':
      return {
        ...state,
        alert: payload,
      };
    case 'REMOVE_ALERT':
      return {
        ...state,
        alert: {
          msg: '',
          type: 'danger',
          hidden: true,
        },
      };
    case 'CREATE_MODAL':
      return {
        ...state,
        modal: { ...state.modal, ...{ persistant: false }, ...payload },
      };
    case 'REMOVE_MODAL':
      return {
        ...state,
        modal: {
          title: '',
          body: '',
          buttons: (
            <>
              <Button
                color='danger'
                onClick={() => {
                  this({
                    type: 'REMOVE_MODAL',
                  });
                }}
              >
                Close me!
              </Button>
              <Button
                color='success'
                onClick={() => {
                  this({
                    type: 'REMOVE_MODAL',
                  });
                }}
              >
                Action 2!
              </Button>
            </>
          ),
          hidden: true,
        },
      };
    case 'SET_OVERLAY':
      return {
        ...state,
        overlay: payload,
      };
    case 'SET_USER':
      return {
        ...state,
        user: payload,
      };
    case 'SET_KIDS':
      const activeObject = payload.find((obj) => obj.active === true);

      return {
        ...state,
        kids: {
          list: payload,
          active: activeObject,
        },
      };
    case 'SET_ACTIVE_KID':
      const kidClone = structuredClone(state.kids.list);
      let activeKid;
      const kidList = kidClone.map((obj) => {
        if (obj.id === payload) {
          activeKid = { ...obj, active: true };
        }
        return obj.id === payload
          ? { ...obj, active: true }
          : { ...obj, active: false };
      });

      return {
        ...state,
        kids: {
          list: kidList,
          active: activeKid,
        },
      };
    case 'UPDATE_KID':
      const updKidClone = structuredClone(state.kids.list);
      let updActiveKid;
      const updKidList = updKidClone.map((obj) => {
        if (obj.id === payload.id) {
          updActiveKid = { ...obj, kidName: payload.kidName, active: true };
        }
        return obj.id === payload.id
          ? { ...obj, kidName: payload.kidName, active: true }
          : { ...obj, active: false };
      });

      return {
        ...state,
        kids: {
          list: updKidList,
          active: updActiveKid,
        },
      };
    case 'REMOVE_KID':
      let remActiveKid;
      let remKidList = state.kids.list.filter((kid) => {
        if (kid.active && kid.id !== payload) remActiveKid = kid;
        return kid.id !== payload;
      });

      if (!remActiveKid) remKidList[0].active = true;

      return {
        ...state,
        kids: {
          list: remKidList,
          active: remActiveKid ? remActiveKid : remKidList[0],
        },
      };
    case 'ADD_KID':
      const addClone = structuredClone(state.kids.list);
      return {
        ...state,
        kids: {
          list: [
            ...addClone.map((o) => (o.active ? { ...o, active: false } : o)),
            {
              kidName: payload.kidName,
              tempKidId: payload.tempKidId,
              loading: true,
              active: true,
            },
          ],
          active: {
            kidName: payload.kidName,
            loading: true,
            active: true,
          },
        },
      };
    case 'FINISH_ADD_KID':
      return {
        ...state,
        kids: {
          list: [
            ...state.kids.list.map((o) =>
              o.tempKidId == payload.tempKidId
                ? { ...o, loading: false, id: payload.id, tempKidId: undefined }
                : o
            ),
          ],
          active: { ...state.kids.active, id: payload.id, loading: false },
        },
      };
    case 'SET_BILLING':
      return {
        ...state,
        billing: payload,
      };
    case 'SET_CODE':
      return {
        ...state,
        code: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};
