import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

const TermsOfService = () => {
  return (
    <>
      <Helmet>
        <title>Terms of Use | Allowance Track</title>
        <meta name='title' content='Terms of Use | Allowance Track'></meta>
        <meta name='description' content='Allowance Track terms of use'></meta>
        <meta
          name='keywords'
          content='allowance, kids, tasks, rewards, money management, track allowances, parental tool, financial responsibility, child tasks, allowance manager, terms, terms and conditions, terms of use'
        ></meta>
        <meta property='og:title' content='Terms of Use | Allowance Track' />
        <meta
          property='og:description'
          content='Allowance Track terms of use'
        ></meta>
        <meta property='og:type' content='website' />
        <meta property='og:url' content={`${process.env.REACT_APP_BASE_URL}`} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content='Terms of Use | Allowance Track' />
        <meta
          name='twitter:description'
          content='Allowance Track terms of use'
        />
      </Helmet>
      <h2 id='terms-of-use'>Allowance Track Terms of Use</h2>
      <p>
        <strong>Allowance Track provides</strong> a task tracking application in
        a free basic version and a paid enhanced Subscription version with
        advanced features. Both versions can be accessed on certain
        Internet-connected devices, including computers, smartphones, tablets,
        and any other device that can access the internet ("Allowance Track
        ready devices"). By accessing and using the Allowance Track application,
        whether the free or paid Subscription version, you agree to comply with
        these terms and any other related terms provided by Allowance Track.
      </p>
      <p>
        <strong>The Allowance Track</strong> service is provided to you by
        Insightful Future LLC. By using the Allowance Track service, you have
        accepted these Terms of Use, which govern your use of our service. As
        used in these Terms of Use, "Allowance Track", "Allowance Track
        service", "our service" or "the service" means the personalized service
        provided by Allowance Track for using the Allowance Track website and/or
        application, including all features and functionalities, recommendations
        and reviews, our websites, and user interfaces, as well as all content
        and software associated with our service. References to ‘you’ in these
        Terms of Use indicate the member who created the Allowance Track
        account, whether free or paid, and whose payment method is charged where
        applicable.
      </p>
      <p>
        <strong>
          <strong></strong>
          YOU AGREE TO THE ARBITRATION AGREEMENT AND CLASS ACTION WAIVER
          DESCRIBED IN SECTION 7 TO RESOLVE ANY DISPUTES WITH ALLOWANCE TRACK
          (EXCEPT FOR MATTERS THAT MAY BE TAKEN TO SMALL CLAIMS COURT).
        </strong>
      </p>
      <p>
        <strong>
          <strong>1</strong>. Membership
        </strong>
      </p>
      <p>
        <strong>1.1.</strong> Your Allowance Track membership and Subscription
        will continue and automatically renew until terminated. To use the
        Allowance Track service, you must have Internet access and an Allowance
        Track ready device and provide us with one or more Payment Methods if
        using a paid account. "Payment Method" means a current, valid, accepted
        method of payment, as may be updated from time to time and which may
        include payment through your account with a third party. You must cancel
        your Subscription before it renews to avoid billing of the Subscription
        fees for the next billing cycle to your Payment Method (see
        "Cancellation" below).
      </p>
      <p>
        <strong>1.2.</strong> We offer membership plans, including free and paid
        Subscription plans. You can find specific details regarding your
        Allowance Track membership by visiting the allowancetrack.com website
        and logging into your account and exploring account related pages.
      </p>
      <p>
        <strong>
          <strong>2</strong>. Promotional Offers.
        </strong>{' '}
        We may from time to time offer special promotional offers, plans or
        memberships (“Offers”). Offer eligibility is determined by Allowance
        Track at its sole discretion and we reserve the right to revoke an Offer
        and put your account on hold in the event that we determine you are not
        eligible. Members of households with an existing or recent Allowance
        Track membership may not be eligible for certain introductory Offers. We
        may use information such as device ID, method of payment or an account
        email address used with an existing or recent Allowance Track membership
        to determine Offer eligibility. The eligibility requirements and other
        limitations and conditions will be disclosed when you sign-up for the
        Offer or in other communications made available to you.
      </p>
      <p>
        <strong>
          <strong>3</strong>. Billing and Cancellation
        </strong>
      </p>
      <p>
        <strong>3.1.</strong> Billing Cycle for paid accounts. The membership
        Subscription fee for the Allowance Track service and any other charges
        you may incur in connection with your use of the service, such as taxes
        and possible transaction fees, will be charged to your Payment Method on
        the specific payment date indicated on your account. Membership
        Subscription fees are fully earned upon payment. In some cases, your
        payment date may change, for example if your Payment Method has not
        successfully settled, when you change your Subscription plan or if your
        paid membership Subscription began on a day not contained in a given
        month. We may authorize your Payment Method in anticipation of
        membership Subscription ice-related charges.
      </p>
      <p>
        <strong>3.2.</strong> Payment Methods. To use the Allowance Track
        service (paid accounts), you must provide one or more Payment Methods.
        You authorize us to charge any Payment Method associated with your
        account in case your primary Payment Method is declined or no longer
        available to us for payment of your Subscription fee. You remain
        responsible for any uncollected amounts. If a payment is not
        successfully settled, due to expiration, insufficient funds, or
        otherwise, and you do not cancel your account, we may suspend your
        access to the service until we have successfully charged a valid Payment
        Method. For some Payment Methods, the issuer may charge you certain
        fees, such as foreign transaction fees or other fees relating to the
        processing of your Payment Method. Check with your Payment Method
        service provider for details.
      </p>
      <p>
        <strong>3.3.</strong> Updating your Payment Methods. You can update your
        Payment Methods by logging into your account and exploring account
        related pages. We may also update your Payment Methods using information
        provided by the payment service providers. Following any update, you
        authorize us to continue to charge the applicable Payment Method(s).
      </p>
      <p>
        <strong>3.4.</strong> Cancellation. You can cancel your Allowance Track
        membership paid Subscription at any time, and you will continue to have
        access to the Allowance Track paid Subscription service through the end
        of your billing period. To cancel, you can log into your account to
        explore account related options for membership and cancel. If you cancel
        your paid membership Subscription, your paid service will automatically
        end at the end of your current billing period.
      </p>
      <p>
        <strong>3.5.</strong> Changes to the Price and Subscription Plans. We
        may change our Subscription plan and the price of our service from time
        to time; however, any price changes or changes to your Subscription plan
        will apply no earlier than 30 days following notice to you. If you do
        not wish to accept the price change or change to your Subscription plan,
        you can cancel your Subscription before the change takes effect.
      </p>
      <p>
        <strong>3.6.</strong> No Refunds. Payments are nonrefundable and there
        are no refunds or credits for partially used membership Subscription
        periods. Following any cancellation for paid accounts, however, you will
        continue to have access to the paid service through the end of your
        current billing period.
      </p>
      <p>
        <strong>
          <strong>4</strong>. Allowance Track Service
        </strong>
      </p>
      <p>
        <strong>4.1.</strong> You must be at least 18 years of age to become a
        member of the Allowance Track service. Individuals under the age of 18
        may only utilize the service with the involvement of a parent or legal
        guardian, under such person's account and otherwise subject to these
        Terms of Use.
      </p>
      <p>
        <strong>4.2.</strong> The Allowance Track service and any content
        accessed through our service are for your personal and non-commercial
        use only. During your Allowance Track membership, we grant you a
        limited, non-exclusive, non-transferable right to access the Allowance
        Track service through the service. Except for the foregoing, no right,
        title or interest shall be transferred to you. You agree not to use the
        service for public performances.
      </p>
      <p>
        <strong>4.3.</strong> You agree to use the Allowance Track service,
        including all features and functionalities associated therewith, in
        accordance with all applicable laws, rules and regulations, or other
        restrictions on use of the service or content therein. You agree not to:{' '}
      </p>
      <p>
        (i) archive, download, reproduce, distribute, modify, display, perform,
        publish, license, create derivative works from, offer for sale, or use
        content and information contained on or obtained from or through the
        Allowance Track service;
      </p>
      <p>
        (ii) circumvent, remove, alter, deactivate, degrade, block, obscure or
        thwart any of the copyright notices, and trademarks;
      </p>
      <p>
        (iii) use any robot, spider, scraper or other automated means to access
        the Allowance Track service;
      </p>
      <p>
        (iv) decompile, reverse engineer or disassemble any software or other
        products or processes accessible through the Allowance Track service;
      </p>
      <p>
        (v) insert any code or product or manipulate the content of the
        Allowance Track service in any way;
      </p>
      <p>(vi) use any data mining, data gathering or extraction method;</p>
      <p>
        (vii) upload, post, e-mail or otherwise send or transmit any material
        designed to interrupt, destroy or limit the functionality of any
        computer software or hardware or telecommunications equipment associated
        with the Allowance Track service, including any software viruses or any
        other computer code, files or programs.
      </p>
      <p>
        We may terminate or restrict your use of our service, at our sole
        discretion, if you violate these Terms of Use, are engaged in illegal or
        fraudulent use of the service, or for any other reason whatsoever. We
        reserve the right to terminate or suspend your account and access to our
        service at any time, without notice, and for any reason, including but
        not limited to your violation of these Terms or any other policy related
        to the service.
      </p>
      <p>
        <strong>4.4.</strong> Customer Support. To find more information about
        our service and its features, or if you need assistance with your
        account, please send an email to support@allowancetrack.com.
      </p>
      <p>
        <strong>
          <strong>5</strong>. Passwords and Account Access.
        </strong>{' '}
        You are responsible for any activity that occurs through the Allowance
        Track account. By allowing others to access the account (which includes
        access to information on tasks and activities for the account), you
        agree that such individuals are acting on your behalf and that you are
        bound by any changes that they may make to the account, including but
        not limited to changes to the Subscription plan. To help maintain
        control over the account and prevent any unauthorized users from
        accessing the account, you should maintain control over the devices that
        are used to access the service and not reveal the password or details of
        the Payment Method associated with the account to anyone. You agree to
        provide and maintain accurate information relating to your account,
        including a valid email address so we can send you account-related
        notices.
      </p>
      <p>
        <strong>
          <strong></strong>
          6. Disclaimers of Warranties and Limitations on Liability
        </strong>
      </p>
      <p>
        <strong>6.1.</strong> THE ALLOWANCE TRACK SERVICE AND ALL CONTENT AND
        SOFTWARE ASSOCIATED THEREWITH, OR ANY OTHER FEATURES OR FUNCTIONALITIES
        ASSOCIATED WITH THE ALLOWANCE TRACK SERVICE, ARE PROVIDED "AS IS" AND
        "AS AVAILABLE" WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND.
        ALLOWANCE TRACK DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR USE
        OF THE ALLOWANCE TRACK SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.
      </p>
      <p>
        <strong>6.2.</strong> TO THE EXTENT PERMISSIBLE UNDER APPLICABLE LAWS,
        IN NO EVENT SHALL ALLOWANCE TRACK, OR ITS SUBSIDIARIES OR ANY OF THEIR
        SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES OR LICENSORS BE LIABLE
        (JOINTLY OR SEVERALLY) TO YOU FOR PERSONAL INJURY OR ANY SPECIAL,
        INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY
        DAMAGES WHATSOEVER.
      </p>
      <p>
        <strong>6.3.</strong> NOTHING IN THESE TERMS OF USE SHALL AFFECT ANY
        NON-WAIVABLE STATUTORY RIGHTS THAT APPLY TO YOU.
      </p>
      <p>
        <strong>7. Arbitration Agreement</strong>
      </p>
      <p>
        <strong>7.1.</strong> You and Allowance Track agree that any dispute,
        claim or controversy arising out of or relating in any way to the
        Allowance Track service, these Terms of Use, and this Arbitration
        Agreement, shall be determined by binding arbitration or in small claims
        court. Arbitration is more informal than a lawsuit in court. Arbitration
        uses a neutral arbitrator instead of a judge or jury, allows for more
        limited discovery than in court, and is subject to very limited review
        by courts. Arbitrators can award the same damages and relief that a
        court can award and nothing in this Arbitration Agreement shall be
        interpreted as limiting any non-waivable statutory rights. You agree
        that, by agreeing to these Terms of Use, the U.S. Federal Arbitration
        Act governs the interpretation and enforcement of this provision, and
        that you and Allowance Track are each waiving the right to a trial by
        jury or to participate in a class action. This arbitration provision
        shall survive termination of this Agreement and the termination of your
        Allowance Track membership or account.
      </p>
      <p>
        <strong>7.2.</strong> If you elect to seek arbitration or file a small
        claim court action, you must first send to Allowance Track, by certified
        mail, a written Notice of your claim ("Notice"). The Notice to Allowance
        Track must be addressed to: General Counsel, Insightful Future:
        Allowance Track, 300 Colonial Center Parkway STE 100N, Roswell, GA,
        30076 ("Notice Address"). If Allowance Track initiates arbitration, it
        will send a written Notice to the email address used for your membership
        account. A Notice, whether sent by you or by Allowance Track, must (a)
        describe the nature and basis of the claim or dispute; and (b) set forth
        the specific relief sought ("Demand"). If Allowance Track and you do not
        reach an agreement to resolve the claim within 90 days after the Notice
        is received, you or Allowance Track may commence an arbitration
        proceeding or file a claim in small claims court.
      </p>
      <p>
        <strong>7.3.</strong> You may download or copy a form Notice and a form
        to initiate arbitration at www.adr.org. If you are required to pay a
        filing fee, Allowance Track will not be responsible for reimbursing any
        fees, and you will be responsible for all filing fees.
      </p>
      <p>
        <strong>7.4.</strong> The arbitration will be governed by the Consumer
        Arbitration Rules (the "AAA Rules") of the American Arbitration
        Association ("AAA"), as modified by this Agreement, and will be
        administered by the AAA. The AAA Rules and Forms are available online at
        www.adr.org. The arbitrator is bound by the terms of this Agreement. All
        issues are for the arbitrator to decide, including issues relating to
        the scope and enforceability of this arbitration agreement. Any
        arbitration hearings will take place in the county (or parish) of
        Allowance Track's choosing. The arbitrator's award shall be final and
        binding on all parties, except (1) for judicial review expressly
        permitted by law or (2) if the arbitrator's award includes an award of
        injunctive relief against a party, in which case that party shall have
        the right to seek judicial review of the injunctive relief in a court of
        competent jurisdiction that shall not be bound by the arbitrator's
        application or conclusions of law.
      </p>
      <p>
        <strong>7.5.</strong> Any claim you bring against Allowance Track cannot
        exceed the total Subscription amount you have paid to Allowance Track.
        Allowance Track will choose whether the arbitration will be conducted
        solely on the basis of documents submitted to the arbitrator, through a
        telephonic hearing, or by an in-person hearing as established by the AAA
        Rules.
      </p>
      <p>
        <strong>7.6.</strong> YOU AND ALLOWANCE TRACK AGREE THAT EACH MAY BRING
        CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND
        NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
        REPRESENTATIVE PROCEEDING. Further, unless both you and Allowance Track
        agree otherwise, the arbitrator may not consolidate more than one
        person's claims with your claims, and may not otherwise preside over any
        form of a representative or class proceeding.
      </p>
      <p>
        <strong>8. Miscellaneous</strong>
      </p>
      <p>
        <strong>8.1.</strong> Governing Law. These Terms of Use shall be
        governed by and construed in accordance with the laws of the state of
        Delaware or Georgia (of Allowance Track's choosing), U.S.A. without
        regard to conflict of laws provisions. These terms will not limit any
        consumer protection rights that you may be entitled to under the
        mandatory laws of your state of residence.
      </p>
      <p>
        <strong>8.2.</strong> Unsolicited Materials. Allowance Track does not
        accept unsolicited materials or ideas for Allowance Track content and is
        not responsible for the similarity of any of its content or programming
        in any media to materials or ideas transmitted to Allowance Track.
        Should you send any unsolicited materials or ideas, you do so with the
        understanding that no additional consideration of any sort will be
        provided to you, and you are waiving any claim against Allowance Track
        and its affiliates regarding the use of such materials and ideas, even
        if material or an idea is used that is substantially similar to the
        material or idea you sent.
      </p>
      <p>
        <strong>8.3.</strong> Feedback. Allowance Track is free to use any
        comments, information, ideas, concepts, reviews, or techniques or any
        other material contained in any communication you may send to us
        ("Feedback"), worldwide and in perpetuity without further compensation,
        acknowledgment or payment to you for any purpose whatsoever including,
        but not limited to, developing, manufacturing and marketing products and
        creating, modifying or improving the Allowance Track service. In
        addition, you agree not to enforce any "moral rights" in and to the
        Feedback, to the extent permitted by applicable law.
      </p>
      <p>
        <strong>8.4.</strong> Customer Support. If you need assistance with your
        account, you can send an email to support@allowancetrack.com. In certain
        instances, Customer Service may best be able to assist you by using a
        remote access support tool through which we have full access to your
        computer. If you do not want us to have this access, you should not
        consent to support through the remote access tool, and we will assist
        you through other means. In the event of any conflict between these
        Terms of Use and information provided by Customer Service or other
        portions of our websites, these Terms of Use will control.
      </p>
      <p>
        <strong>8.5.</strong> Survival. If any provision or provisions of these
        Terms of Use shall be held to be invalid, illegal, or unenforceable, the
        validity, legality and enforceability of the remaining provisions shall
        remain in full force and effect.
      </p>
      <p>
        <strong>8.6.</strong> Changes to Terms of Use and Assignment. Allowance
        Track may, from time to time, change these Terms of Use. Such revisions
        shall be effective immediately; provided however, for existing members,
        such revisions shall, unless otherwise stated, be effective 30 days
        after posting. We may assign our agreement with you to any affiliated
        company or to any entity that succeeds to all or substantially all of
        our business or assets related.
      </p>
      <p>
        <strong>Last Updated: October 14, 2023</strong>
      </p>
    </>
  );
};
export default TermsOfService;
