import TopCards from '../components/TopCards';
import InputCard from '../components/InputCard';
import TaskCard from '../components/TaskCard';
import React, {
  createElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Confetti from 'react-confetti';
import { AllowanceContext } from '../context/AllowanceContext';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';
import Joyride from 'react-joyride';
// import Lottie from "react-lottie";

import rocket from '../assets/icons/rocket.gif';
import coins from '../assets/icons/coins.gif';
import documentIcon from '../assets/icons/documents.gif';
import todo from '../assets/icons/todo.gif';
import piggybank from '../assets/icons/piggybank.gif';
import thumbsup from '../assets/icons/thumbsup.gif';

const Home = () => {
  const {
    fetchTasksFromDB,
    loading,
    alert,
    percentCompleted,
    week,
    prevWeek,
    setPrevWeek,
    overlay,
    setOverlay,
    user: ctxUser,
    tasksLoaded,
    tasks,
  } = useContext(AllowanceContext);

  const { isAuthenticated, isLoading: auth0Loading } = useAuth0();

  const [prevOverlay, setPrevOverlay] = useState();

  const [prevPlan, setPrevPlan] = useState();

  const [speed, setSpeed] = useState(1);
  // useEffect(() => {
  //   if (overlay && overlay !== prevOverlay) {
  //     const div = document.createElement('div');
  //     div.className = 'modal-backdrop fade show';
  //     div.id = 'modalbg';
  //     div.onclick =
  //       overlay !== 'modal'
  //         ? async () => {
  //             setOverlay(false);
  //             window.document.body.lastElementChild.id == 'modalbg' &&
  //               window.document.body.removeChild(
  //                 window.document.body.lastElementChild
  //               );
  //             if (week.str !== prevWeek) {
  //               setPrevWeek(week.str);
  //               isAuthenticated &&
  //                 !auth0Loading &&
  //                 (await fetchTasksFromDB(week.str));
  //             }
  //           }
  //         : async () => {
  //             setOverlay(false);
  //             window.document.body.lastElementChild.id == 'modalbg' &&
  //               window.document.body.removeChild(
  //                 window.document.body.lastElementChild
  //               );
  //             if (week.str !== prevWeek) {
  //               setPrevWeek(week.str);
  //               isAuthenticated &&
  //                 !auth0Loading &&
  //                 (await fetchTasksFromDB(week.str));
  //             }
  //           };
  //
  //     window.document.body.appendChild(div);
  //   } else {
  //     window.document.body.lastElementChild.id == 'modalbg' &&
  //       window.document.body.removeChild(window.document.body.lastElementChild);
  //   }
  //   setPrevOverlay(overlay);
  // }, [overlay]);

  // State to control whether to run Joyride
  const [runTour, setRunTour] = useState(false);
  // const [runTour, setRunTour] = useState(true);

  useEffect(() => {
    // Check if the tour was already completed
    const tourCompleted = localStorage.getItem('tourCompleted') === 'true';

    if (
      !tourCompleted &&
      (!process.env.REACT_APP_EMAIL_VERIFICATION || ctxUser.email_verified)
    ) {
      // Start the tour if it hasn't been completed
      setRunTour(true);
    }
  }, [ctxUser]);

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  // Listen to context changes
  useEffect(() => {
    // Replace 'yourContextState' with the actual state you want to monitor
    if (
      tasks &&
      tasks.length !== 0 &&
      !auth0Loading &&
      isAuthenticated &&
      tasksLoaded &&
      currentStepIndex === 2
    ) {
      // Move to the next step when the condition is met
      setTimeout(() => setCurrentStepIndex(3), 200);
    }
  }, [tasks, auth0Loading, isAuthenticated, tasksLoaded]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = ['finished', 'skipped'];
    const { action, index, type } = data;

    setSpeed(0.5);

    // Update step index on user interactions
    if (type === 'step:after' || type === 'toolbar') {
      if (action === 'next') {
        setCurrentStepIndex(index + 1);
      } else if (action === 'prev') {
        setCurrentStepIndex(index - 1);
      }
    }

    if (finishedStatuses.includes(status)) {
      // Update local storage when the tour is finished
      localStorage.setItem('tourCompleted', 'true');
      setRunTour(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Allowance Track
          {ctxUser && ctxUser.plan == 'paid' ? ' Plus' : ''}
        </title>
        <meta
          name='title'
          content="Allowance Track | Manage Kids' Allowances Easily"
        ></meta>
        <meta
          name='description'
          content="Allowance Track helps parents track and manage their kids' allowances based on tasks completion. Set tasks, monitor progress, and reward your children with our intuitive interface!"
        ></meta>
        <meta
          name='keywords'
          content='allowance, kids, tasks, rewards, money management, track allowances, parental tool, financial responsibility, child tasks, allowance manager'
        ></meta>
        <meta
          name='keywords'
          content='allowance, kids, tasks, rewards, money management, track allowances, parental tool, financial responsibility, child tasks, allowance manager'
        ></meta>
        <meta
          property='og:title'
          content="Allowance Track | Manage Kids' Allowances Easily"
        />
        <meta
          property='og:description'
          content="Allowance Track helps parents track and manage their kids' allowances based on tasks completion. Set tasks, monitor progress, and reward your children with our intuitive interface!"
        ></meta>
        <meta property='og:type' content='website' />
        <meta property='og:url' content={`${process.env.REACT_APP_BASE_URL}`} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:title'
          content="Allowance Track | Manage Kids' Allowances Easily"
        />
        <meta
          name='twitter:description'
          content="Allowance Track helps parents track and manage their kids' allowances based on tasks completion. Set tasks, monitor progress, and reward your children with our intuitive interface!"
        />
      </Helmet>
      <Joyride
        run={runTour}
        steps={[
          {
            title: (
              <div className='fs-4'>
                <img
                  src={rocket}
                  alt='Welcome'
                  className='animated-icon'
                  style={{
                    width: '100px',
                    height: '100px',
                    display: 'block',
                    margin: 'auto',
                  }}
                />
                Welcome to allowance track!
              </div>
            ),
            content:
              'We are so excited to have you here. Here is a quick feature tour so that you can get started fast!',
            disableBeacon: true,
            placement: 'center',
            target: 'body',
          },
          {
            target: '#max-allowance',
            title: (
              <div className='fs-4'>
                <img
                  src={piggybank}
                  alt='Weekly Allowance'
                  className='animated-icon'
                  style={{
                    width: '100px',
                    height: '100px',
                    display: 'block',
                    margin: 'auto',
                  }}
                />
                Enter your kid's weekly allowance!
              </div>
            ),
            content:
              'This is where you enter the amount of money your child earns each week if they finish all of their chores.',
            disableBeacon: true,
            placement: 'bottom-end',
            floaterProps: {
              placement: 'bottom-end',
            },
          },
          {
            target: '#inp-group',
            title: (
              <div className='fs-4'>
                <img
                  src={documentIcon}
                  alt='Add Chore'
                  className='animated-icon'
                  style={{
                    width: '100px',
                    height: '100px',
                    display: 'block',
                    margin: 'auto',
                  }}
                />
                Add your first chore!
              </div>
            ),
            content:
              'This is where you add a chore. Chores are tasks your child must complete weekly to earn their allowance. Go ahead and add a chore!',
            disableBeacon: true,
          },
          {
            target: '#task-table',
            title: (
              <div className='fs-4'>
                <img
                  src={todo}
                  alt='Chore Chart'
                  className='animated-icon'
                  style={{
                    width: '100px',
                    height: '100px',
                    display: 'block',
                    margin: 'auto',
                  }}
                />
                The chore chart!
              </div>
            ),
            content:
              'This is where you view all the chores you have added and check off days when the chore has been completed. Try checking off a day!',
            disableBeacon: true,
            // spotlightPadding: 0,
          },
          {
            target: '#current-earned',
            title: (
              <div className='fs-4'>
                <img
                  src={coins}
                  className='animated-icon'
                  alt='Amount Earned'
                  style={{
                    width: '100px',
                    height: '100px',
                    display: 'block',
                    margin: 'auto',
                  }}
                />
                Your amount earned!
              </div>
            ),
            content:
              'This amount will automatically update as you check off chores to show how much of your weekly allowance you have earned.',
            disableBeacon: true,
          },
          {
            title: (
              <div className='fs-4'>
                <img
                  src={thumbsup}
                  alt="That's it!"
                  className='animated-icon'
                  style={{
                    width: '100px',
                    height: '100px',
                    display: 'block',
                    margin: 'auto',
                  }}
                />
                That's it!
              </div>
            ),
            content: "It's that easy. Enjoy using Allowance Track!",
            disableBeacon: true,
            placement: 'center',
            target: 'body',
          },
        ]}
        showProgress={true}
        continuous={true}
        spotlightClicks={true}
        disableScrollParentFix={true}
        // disableScrolling={true}
        spotlightPadding={10}
        stepIndex={currentStepIndex}
        showSkipButton={true}
        hideCloseButton={true}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          open: 'Open the dialog',
          skip: 'Close',
        }}
        callback={handleJoyrideCallback}
        // other props
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#0d6efd',
          },
          overlay: {
            pointerEvents: 'none',
          },
        }}
      />

      {/* Main Content 
        This is the main content of the page
      */}

      <div
        initial={{ opacity: '0' }}
        animate={{ opacity: '1' }}
        className='card card-body m-auto mb-3 mx-3'
        id='main-content'
        style={{ border: 'none' }}
      >
        {percentCompleted == '100' && (
          <Confetti
            width={window && window.innerWidth}
            height={window && window.innerHeight}
            colors={['#0d6efd', '#198754', '#0dcaf0', '#dc3545', '#ffc107']}
          />
        )}
        <TopCards />
        <InputCard />
        <TaskCard key={'123479812156798235967028375940'} />
      </div>
      {/* End of Main Content */}
    </>
  );
};

export default Home;
