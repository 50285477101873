import Card from './shared/Card';
import Button from './shared/Button';
import { AllowanceContext } from '../context/AllowanceContext';
import { useContext, useEffect, useRef, useState } from 'react';
import TaskRow from './TaskRow';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import opacity from 'react-element-popper/animations/opacity';
import Footer from 'react-multi-date-picker/plugins/range_picker_footer';
import spinner from '../assets/wired-gradient-105-loader-1 (1)-min.gif';
import { useAuth0 } from '@auth0/auth0-react';
import { AnimatePresence, Group, motion } from 'framer-motion';

const MotionCard = motion(Card);

const TaskCard = () => {
  const {
    tasks,
    clearChecks,
    setWeek,
    changeWeek,
    week,
    overlay,
    setOverlay,
    getWeekStartAndEndDates,
    loading,
    fetchTasksFromDB,
    prevWeek,
    setPrevWeek,
    user,
    createModal,
    tasksLoaded,
  } = useContext(AllowanceContext);

  const [clear, setClear] = useState(false);

  const {
    isAuthenticated,
    isLoading: auth0Loading,
    loginWithRedirect,
  } = useAuth0();

  const calenderRef = useRef();

  const MotionTaskRow = motion(TaskRow);

  return (
    <MotionCard className='w-70 center-self' id='results' key={1}>
      <div
        className='text-center center-self'
        style={{ width: '90%', padding: '1rem' }}
        key={'buq34wilbyug1o893p4'}
      >
        <motion.form id='complete-form'>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              className='btn btn-outline-info btn-light-info-not-hover btn-date-picker'
              data-bs-toggle={
                (!isAuthenticated && !loading) || (user && user.plan == 'free')
                  ? 'modal'
                  : false
              }
              data-bs-target={
                (!isAuthenticated && !loading) || (user && user.plan == 'free')
                  ? '#modal'
                  : false
              }
              onClick={() => {
                if (isAuthenticated && !loading) {
                  if (user && user.plan == 'free') {
                    createModal(
                      'Upgrade to navigate weeks!',
                      'PRICING',
                      'PLAN_REQUIRED'
                    );
                  } else {
                    changeWeek('subtract', 7, 'days');
                    setPrevWeek(
                      new DateObject(week.str)
                        .subtract(7, 'days')
                        .format('YYYY-MM-DD')
                    );
                  }
                } else {
                  createModal(
                    'Sign up & Upgrade to navigate weeks!',
                    <>
                      <p className='my-0' style={{ textAlign: 'left' }}>
                        You have to sign up for a paid plan to be able to
                        navigate weeks!
                      </p>
                    </>,
                    'ACCOUNT_REQUIRED'
                  );
                }
              }}
              style={{
                border: 'none',
                paddingLeft: '8.8px',
                paddingRight: '8.8px',
              }}
            >
              <i className='fa-solid fa-circle-left fa-lg'></i>
            </div>
            <DatePicker
              range
              portal
              format='YYYY-MM-DD'
              inputClass='form-control'
              className='z-max shadow-sm p-2 mb-5 rounded modal modal-dialog modal-content custom-modal'
              ref={calenderRef}
              render={(value, showCalender) => {
                return (
                  <span
                    data-bs-toggle={
                      (!isAuthenticated && !loading) ||
                      (user && user.plan == 'free')
                        ? 'modal'
                        : false
                    }
                    data-bs-target={
                      (!isAuthenticated && !loading) ||
                      (user && user.plan == 'free')
                        ? '#modal'
                        : false
                    }
                    onClick={() => {
                      if (user && user.plan == 'free') {
                        createModal(
                          'Upgrade to navigate weeks!',
                          'PRICING',
                          'PLAN_REQUIRED'
                        );
                        return;
                      } else if (isAuthenticated && !loading) {
                        showCalender();
                        setOverlay(true);
                      } else {
                        createModal(
                          'Sign up & Upgrade to navigate weeks!',
                          <>
                            <p className='my-0' style={{ textAlign: 'left' }}>
                              You have to sign up for a paid plan to be able to
                              navigate weeks!
                            </p>
                          </>,
                          'ACCOUNT_REQUIRED'
                        );
                      }
                    }}
                    className='btn btn-outline-info btn-light-info-not-hover btn-date-picker'
                    style={{
                      cursor: 'pointer',
                      display: 'block',
                      color: 'white',
                      border: 'none',
                    }}
                  >
                    <h5
                      className='fa-lg calender-date'
                      style={{
                        display: 'inline',
                        marginRight: '1rem',
                      }}
                    >
                      {week.val[0].format(
                        `DD ${
                          week.val[0].month.name != week.val[1].month.name
                            ? 'MMM'
                            : ''
                        } ${week.val[0].year != week.val[1].year ? 'YYYY' : ''}`
                      )}{' '}
                      - {week.val[1].format('DD MMM YYYY')}
                    </h5>
                    <i
                      className='fa-solid fa-calendar-week fa-lg'
                      style={{ cursor: 'pointer' }}
                    ></i>
                  </span>
                );
              }}
              calendarPosition='bottom-center'
              animations={[opacity()]}
              weekStartDayIndex={1}
              arrowStyle={{
                display: 'none',
              }}
              style={{
                visibility: overlay ? 'visible' : 'none',
              }}
              value={week.val}
              onChange={(val) => {
                val[0].subtract(1, 'day');
                const week = getWeekStartAndEndDates(
                  val[0].format('YYYY-MM-DD')
                );
                val = week.val;
                setWeek(week);
              }}
              showOtherDays
              // plugins={[
              //   <Footer
              //     position='bottom'
              //     className='footer'
              //     names={{
              //       selectedDates: 'Week:',
              //       from: '',
              //       to: '',
              //       separator: '-',
              //     }}
              //   />,
              // ]}
            >
              <div
                className='btn-group controls'
                role='group'
                aria-label='Basic outlined example'
              >
                <Button
                  outline
                  color='success'
                  style={{ width: '50%' }}
                  onClick={() => {
                    if (user && user.plan == 'free') {
                      createModal(
                        'Upgrade to navigate weeks!',
                        'PRICING',
                        'PLAN_REQUIRED'
                      );
                    } else if (week.str != prevWeek) {
                      isAuthenticated &&
                        !auth0Loading &&
                        fetchTasksFromDB(week.str);
                      setPrevWeek(week.str);
                    }
                    setOverlay(false);
                    calenderRef.current && calenderRef.current.closeCalendar();
                  }}
                >
                  <i className='fas fa-check'></i>
                </Button>
                <Button
                  outline
                  color='info'
                  className='btn-outline-info-light'
                  style={{
                    width: '100%',
                  }}
                  onClick={() => {
                    const startEnd = getWeekStartAndEndDates(new Date());
                    calenderRef.current && calenderRef.current.closeCalendar();
                    setTimeout(() => {
                      calenderRef.current.openCalendar();
                    }, 0.00000000001);
                    setWeek({
                      ...startEnd,
                      val: [
                        new DateObject(startEnd.val[0]),
                        new DateObject(startEnd.val[1]),
                      ],
                    });
                  }}
                >
                  Today
                </Button>
                <Button
                  outline
                  color='danger'
                  onClick={() => {
                    setWeek(getWeekStartAndEndDates(prevWeek.str));
                    setOverlay(false);
                    calenderRef.current && calenderRef.current.closeCalendar();
                  }}
                  style={{ width: '50%' }}
                >
                  <i className='fas fa-xmark'></i>
                </Button>
              </div>
            </DatePicker>
            <div
              className='btn btn-outline-info btn-light-info-not-hover btn-date-picker'
              data-bs-toggle={
                (!isAuthenticated && !loading) || (user && user.plan == 'free')
                  ? 'modal'
                  : false
              }
              data-bs-target={
                (!isAuthenticated && !loading) || (user && user.plan == 'free')
                  ? '#modal'
                  : false
              }
              onClick={() => {
                if (user && isAuthenticated && !loading) {
                  if (
                    user.plan != 'paid' &&
                    user.plan != 'admin' &&
                    user.plan != 'friend'
                  ) {
                    createModal(
                      'Upgrade to navigate weeks!',
                      'PRICING',
                      'PLAN_REQUIRED'
                    );
                  } else {
                    changeWeek('add', 7, 'days');
                    setPrevWeek(
                      new DateObject(week.str)
                        .add(7, 'days')
                        .format('YYYY-MM-DD')
                    );
                  }
                  // } else {
                  //   createModal(
                  //     'Sign up & Upgrade to navigate weeks!',
                  //     <>
                  //       <p className='my-0' style={{ textAlign: 'left' }}>
                  //         You have to sign up for a paid plan to be able to
                  //         navigate weeks!
                  //       </p>
                  //     </>,
                  //     'ACCOUNT_REQUIRED'
                  //   );
                }
              }}
              style={{
                border: 'none',
                paddingLeft: '8.8px',
                paddingRight: '8.8px',
              }}
            >
              <i className='fa-solid fa-circle-right fa-lg'></i>
            </div>
          </div>
          {/* <Group> */}
          {loading || !tasksLoaded ? (
            <motion.img
              initial={{ opacity: '0' }}
              animate={{ opacity: '1' }}
              src={spinner}
              width='200'
              height='200'
            />
          ) : !(!tasks || (tasks && tasks.length === 0)) ? (
            <motion.div
              initial={{ opacity: '0' }}
              animate={{ opacity: '1' }}
              className='table-responsive text-nowrap'
            >
              <table className='table table-hover-custom' id='task-table'>
                <thead>
                  <tr>
                    <th>Chore</th>
                    <th>|</th>
                    <th>Mon</th>
                    <th>Tue</th>
                    <th>Wed</th>
                    <th>Thu</th>
                    <th>Fri</th>
                    <th>Sat</th>
                    <th>Sun</th>
                    <th>
                      {/* <Button
                        href='#'
                        outline
                        color='secondary'
                        className='py-1 font-sm'
                        id='clear-checks'
                        onClick={() => {
                          clearChecks();
                          setClear(true);
                          setTimeout(() => setClear(false), 200);
                        }}
                      >
                        Uncheck All
                      </Button> */}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* <AnimatePresence> */}
                  {tasks.map((task) => {
                    return (
                      <TaskRow
                        key={task.id.toString()}
                        task={task}
                        clear={clear}
                      />
                    );
                  })}
                  {/* </AnimatePresence> */}
                </tbody>
              </table>
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: '0' }}
              animate={{ opacity: '1' }}
              className='text-center center-self'
              id='task-table'
              style={{
                width: '100%',
                padding: '1rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
            >
              <h4
                className='display-5 rounded'
                style={{
                  fontSize: '2.5rem',
                  opacity: '0.4',
                }}
              >
                No chores
              </h4>
              <Button
                outline
                color='secondary'
                onClick={() => {
                  isAuthenticated &&
                    !auth0Loading &&
                    fetchTasksFromDB('prev', week.str);
                }}
                style={{ marginTop: '0.5rem' }}
              >
                Add Previous Week's Chores
              </Button>
            </motion.div>
          )}
          {/* </Group> */}
        </motion.form>
      </div>
    </MotionCard>
  );
};

export default TaskCard;
