import React, { useEffect, useRef, useState } from 'react';
// import { motion } from 'framer-motion';
// import highQualityImage from '../assets/Allowance Track - Best Chore Tracking App High Quality.jpg';
import image from '../assets/Allowance Track - Best Chore Tracking App.jpg';
import Button from './shared/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// const LazyLoadImage = motion(LazyLoadImageComponent);

function Hero() {
  const { loginWithRedirect } = useAuth0();
  // Use useScroll to track vertical scroll position
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY]);
  // Create transformed motion values based on scrollYProgress
  // const scaleY = useTransform(scrollYProgress, [0, 1], [0.5, 1]); // Image size increases from 50% to 100% over full scroll
  // const borderRadius = useTransform(scrollYProgress, [0, 1], [50, 0]); // Border radius goes from 50 to 0 over full scroll

  return (
    <div className='container w-sm-100' id='hero'>
      <Helmet>
        <link rel='preload' href={image} as='image' />
      </Helmet>
      <div
        style={{
          height: '50dvh',
          position: 'relative',
          width: '100%',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            msTransform: 'translate(-50%, -50%)',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            margin: 0,
          }}
        >
          <div>
            <h1 className='display-3 fw-thin' id='tagline'>
              The Future of{' '}
              <span className='animated-text'>
                <span>Chore Tracking</span>
              </span>{' '}
              is here
            </h1>
            <h3
              className='fw-normal text-muted mb-3 fs-5 w-75 m-auto font-md-responsive'
              id='tag-desc'
            >
              In today's fast-paced digital age, managing your child's chores
              and allowance shouldn't be a chore in itself. With Allowance
              Track, we've reimagined the entire process, ensuring it's more
              streamlined, transparent, and interactive than ever before with
              the ultimate chore chart.
            </h3>
            <div className='d-flex gap-3 justify-content-center lead fw-normal'>
              <Button
                color='primary'
                className='bg-animated-b-g'
                onClick={() => {
                  loginWithRedirect({
                    authorizationParams: { screen_hint: 'signup' },
                  });
                }}
              >
                Start For Free
              </Button>
            </div>
          </div>
        </div>
      </div>
      <img
        className='img-fluid mx-auto d-block'
        style={{
          width: '90%',
          transformOrigin: 'top',
          // scaleY: scaleY,
          // scaleX: scaleY,
          // borderRadius: `${borderRadius}%`,
          // scaleX: scrollY,
          willChange: 'scale',
          borderRadius: '50px',
        }}
        src={image}
        // placeholderSrc={image}
        loading='eager'
        priority='high'
        alt='Description'
      />
    </div>
  );
}

export default Hero;
