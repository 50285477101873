import Card from './shared/Card';
import Button from './shared/Button';
import { useContext, useRef, useState } from 'react';
import { AllowanceContext } from '../context/AllowanceContext';
const TopCards = () => {
  const {
    earned,
    earnableValue,
    setEarnableValue,
    setEarnable,
    percentCompleted,
    loading,
    tokenExpired,
  } = useContext(AllowanceContext);
  const [edit, setEdit] = useState(null);

  const inpRef = useRef(null);

  return (
    <div
      className='responsive text-center grid center-self w-70 my-4 mt-8'
      id='cards-grid'
    >
      {/* Three Cards 
          The three cards below the to bar that display important information
        */}
      <div className='row row-m m-0'>
        {/* First Card 
              This card displays the current amount of money that you have earned 
            */}
        <Card
          className='col b-l-primary py-2 media-p-t'
          id='current-earned'
          style={{
            marginLeft: '0 !important',
            marginRight: '0 !important',
          }}
        >
          <div className='card-body media-p my-auto'>
            <div className='row align-items-center media-my'>
              <div className='col'>
                <h4>Earned</h4>
                <div className='h4 mb-0' id='earned'>
                  {loading ? '--.--' : `$${earned}`}
                </div>
              </div>
              <div className='col-auto fa-wrapper'>
                <i className='fa-solid fa-dollar-sign fa-2x text-gray-300' />
              </div>
            </div>
          </div>
        </Card>
        {/* End of First Card */}
        {/* Second Card 
        This card allows you to enter the money you earn per week defaulting at $6
    */}
        <Card
          className='col b-l-info py-2 mx-3 m-font middle-card'
          id='max-allowance'
        >
          <div className='card-body media-p my-auto'>
            <div className='row align-items-center m-mr-0'>
              <div className='col m-p-400'>
                <h6>Allowance</h6>
                <form
                  action='#'
                  id='money-form'
                  onSubmit={(e) => {
                    e.preventDefault();
                    setEarnable(earnableValue);
                    setEdit(!edit);
                  }}
                >
                  <div className='input-group input-group-media input-group-sm'>
                    {/* Dollar Sign 
                      The dollar sign before the text input
                */}
                    <div
                      className='input-group-prepend'
                      style={{
                        borderTopRightRadius: '0',
                        borderBottomRightRadius: '0',
                      }}
                    >
                      <span
                        className='input-group-text'
                        style={{
                          borderTopRightRadius: '0',
                          borderBottomRightRadius: '0',
                        }}
                      >
                        $
                      </span>
                    </div>
                    {/* End Of Dollar Sign */}
                    {/* Input
                      The actual input where you enter the money you earn per week
                */}
                    <input
                      type='text'
                      min={1}
                      // readOnly={edit}
                      disabled={tokenExpired}
                      onFocus={() => setEdit(false)}
                      onBlur={(e) => {
                        console.log(
                          !(
                            e.relatedTarget &&
                            e.relatedTarget.id &&
                            e.relatedTarget.id == 'submit-2'
                          ) &&
                            !(
                              e.relatedTarget &&
                              e.relatedTarget.id &&
                              e.relatedTarget.parentElement.id == 'submit-2'
                            )
                        );
                        if (
                          !(
                            e.relatedTarget &&
                            e.relatedTarget.id &&
                            e.relatedTarget.id == 'submit-2'
                          ) &&
                          !(
                            e.relatedTarget &&
                            e.relatedTarget.id &&
                            e.relatedTarget.parentElement.id == 'submit-2'
                          )
                        ) {
                          setEarnable(earnableValue);
                          setEdit(true);
                        }
                      }}
                      onKeyDown={(e) => {
                        const { keyCode, key, target } = e;
                        const value = target.value;
                        (keyCode === 69 ||
                          keyCode === 189 ||
                          keyCode === 187 ||
                          (key === '.' && value.includes('.'))) &&
                          e.preventDefault();
                        if (value.includes('.')) {
                          const decimalIndex = value.indexOf('.');
                          const decimalDigits = value.substring(
                            decimalIndex + 1
                          );

                          if (
                            decimalDigits.length >= 2 &&
                            ![37, 39, 8].includes(keyCode)
                          ) {
                            e.preventDefault();
                          }
                        }
                      }}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        // Allow only numeric input
                        if (!newValue || newValue.match(/^\d+$/)) {
                          setEarnableValue(e.target.value);
                        }
                      }}
                      inputMode='numeric'
                      pattern='[0-9]*'
                      className='form-control custom-form-control'
                      id='per-week'
                      value={earnableValue}
                      ref={inpRef}
                      style={{ fontSize: '16px' }}
                      step='any'
                      required
                    />
                    {/* End of Input */}
                    {/* Submit Button 
                      The button you use to submit the value you entered
                */}
                    <Button
                      className={`${
                        edit || edit === null
                          ? 'btn-light-info-not-hover'
                          : 'btn-light-success-not-hover'
                      }`}
                      outline
                      color={!edit && edit !== null ? 'success' : 'info'}
                      disabled={tokenExpired}
                      onClick={() => {
                        setTimeout(() => edit && inpRef.current.focus(), '1');
                      }}
                      style={{ border: 'none' }}
                      type='submit'
                      id='submit-2'
                    >
                      {edit || edit === null ? (
                        <i className='fa-solid fa-pen'></i>
                      ) : (
                        <i className='fa-solid fa-check'></i>
                      )}
                    </Button>
                    {/* End of Submit Button */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Card>
        {/* End of Second Card */}
        {/* Third Card 
          The card that displays the percent value that you have completed
    */}
        <Card className='col b-l-success py-2' id='percent-done'>
          <div className='card-body media-p align-middle'>
            <div className='row align-items-center align-middle media-my'>
              <div className='col align-middle align-self-center'>
                <h4>Completed</h4>
                <div className='h4 mb-0' id='percent'>
                  {loading ? '--' : `${percentCompleted}%`}
                </div>
              </div>
              <div className='col-auto align-middle fa-wrapper'>
                <i className='fas fa-percent fa-2x text-gray-300' />
              </div>
            </div>
          </div>
        </Card>
        {/* End of Third Card */}
      </div>
    </div>
  );
};

export default TopCards;
