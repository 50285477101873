import Card from './shared/Card';
import Button from './shared/Button';
import { useContext, useState, useEffect, useReducer, useRef } from 'react';
import { AllowanceContext } from '../context/AllowanceContext';
import { useAuth0 } from '@auth0/auth0-react';
const InputCard = () => {
  const {
    addTask,
    tasks,
    createAlert,
    taskExists,
    loading,
    tokenExpired,
    createModal,
  } = useContext(AllowanceContext);
  const { isAuthenticated } = useAuth0();
  const [taskName, setTaskName] = useState('');
  const [hidden, setHidden] = useState(true);
  const [inpToggle, setInpToggle] = useState(true);
  const [allowedDays, setAllowedDays] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const setAllowedDay = (index, value) => {
    let newDays = [...allowedDays];
    newDays[index] = value;

    setAllowedDays(newDays);
  };

  const clearAllowedDays = () => {
    setAllowedDays([...Array.from({ length: 7 }, () => true)]);
  };

  return (
    <>
      {/* Task Input Card
        The card surrounding the input where you enter your tasks
      */}
      <Card className='w-70 center-self mb-4' id='inp-card'>
        <div className='text-center center-self' style={{ width: '90%' }}>
          <form
            id='task-form'
            onSubmit={async (e) => {
              e.preventDefault();
              if (taskExists(taskName)) {
                createAlert(`The chore ${taskName} already exists`, 'danger');
              } else if (taskName == '' || !taskName) {
                createAlert(`Please enter a chore`, 'danger');
              } else {
                await addTask(taskName, allowedDays);
                setTaskName('');
                clearAllowedDays();
                setHidden(true);
              }
            }}
          >
            <div className='form-group mb-3' />
            {/* Task Input
              The input where you enter your tasks
            */}
            <div id='inputs'>
              <div className='input-group mb-3' id='inp-group'>
                <Button
                  outline
                  color='secondary'
                  onClick={() => {
                    setHidden(!hidden);
                  }}
                >
                  <i className='fas fa-caret-down' />
                </Button>
                <input
                  type='text'
                  className='form-control'
                  id='tasks'
                  placeholder='Enter a chore'
                  value={taskName}
                  onFocus={() => {
                    if (inpToggle) {
                      setHidden(false);
                    }
                  }}
                  onChange={(e) => setTaskName(e.target.value)}
                  readOnly={
                    loading ||
                    tasks.some((task) => task.loading) ||
                    tokenExpired
                  }
                />
                <Button
                  outline
                  color='dark'
                  type='submit'
                  disabled={
                    loading ||
                    tasks.some((task) => task.loading) ||
                    tokenExpired
                  }
                >
                  Submit
                </Button>
              </div>
              <div
                className={
                  hidden == true || tokenExpired
                    ? 'collapse-custom text-nowrap table-responsive rounded hide table-scroll'
                    : 'collapse-custom show text-nowrap table-responsive rounded table-scroll'
                }
                id='descriptionCollapse'
                style={{
                  overflowX: 'auto',
                }}
              >
                <table
                  className='table table-hover-custom'
                  style={{ width: '90%', margin: 'auto' }}
                >
                  <thead>
                    <tr>
                      <th className='show'></th>
                      <th className='show'>Mon</th>
                      <th className='show'>Tue</th>
                      <th className='show'>Wed</th>
                      <th className='show'>Thu</th>
                      <th className='show'>Fri</th>
                      <th className='show'>Sat</th>
                      <th className='show'>Sun</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='not-desktop'>
                      <td colSpan={9}>
                        <p
                          style={{
                            margin: '0',
                            fontWeight: 'bold',
                          }}
                        >
                          Days required:
                        </p>
                      </td>
                    </tr>
                    <tr className='not-desktop'>
                      {allowedDays.map((day, i) => {
                        const dayLetter = 'MTWTFSS'[i];
                        return (
                          <td key={i}>
                            <div
                              className='form-check form-switch form-control-sm m-0 p-0'
                              style={{
                                padding: '0',
                                margin: 'auto',
                                width: 'fit-content',
                              }}
                            >
                              <label className='mb-2 d-block'>
                                {dayLetter}
                              </label>
                              <input
                                type='checkbox'
                                checked={day}
                                data-bs-toggle={!isAuthenticated && 'modal'}
                                data-bs-target={!isAuthenticated && '#modal'}
                                onChange={(e) => {
                                  if (!isAuthenticated) {
                                    createModal(
                                      'Sign up to set required days!',
                                      <>
                                        <p
                                          className='my-0'
                                          style={{ textAlign: 'left' }}
                                        >
                                          You have to sign up to be able to set
                                          days required!
                                        </p>
                                      </>,
                                      'ACCOUNT_REQUIRED'
                                    );
                                  } else {
                                    setAllowedDay(i, e.target.checked);
                                  }
                                }}
                                className='form-check-input d-block'
                                style={{
                                  margin: 'auto',
                                }}
                                role='switch'
                              />
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                    <tr id='desktop-only'>
                      <td>
                        <p
                          style={{
                            margin: '0',
                            fontWeight: 'bold',
                          }}
                        >
                          Days required:
                        </p>
                      </td>
                      {allowedDays.map((day, i) => {
                        return (
                          <td key={i}>
                            <div
                              className='form-check form-switch'
                              style={{
                                padding: '0',
                                margin: 'auto',
                                width: 'fit-content',
                              }}
                            >
                              <input
                                type='checkbox'
                                checked={day}
                                data-bs-toggle={!isAuthenticated && 'modal'}
                                data-bs-target={!isAuthenticated && '#modal'}
                                onChange={(e) => {
                                  if (!isAuthenticated) {
                                    createModal(
                                      'Sign up to set required days!',
                                      <>
                                        <p
                                          className='my-0'
                                          style={{ textAlign: 'left' }}
                                        >
                                          You have to sign up to be able to set
                                          days required!
                                        </p>
                                      </>,
                                      'ACCOUNT_REQUIRED'
                                    );
                                  } else {
                                    setAllowedDay(i, e.target.checked);
                                  }
                                }}
                                className='form-check-input mx-3'
                                role='switch'
                              />
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
                <div style={{ height: '1rem' }}></div>
              </div>
            </div>
            {/* End of Task Input */}
          </form>
        </div>
      </Card>
      {/* End of Task Input Card */}
    </>
  );
};
export default InputCard;
