import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/style.css';
import './css/utilities.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router } from 'react-router-dom';
import AllowanceProvider from './context/AllowanceContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_URL}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          // redirect_uri: 'http://localhost:3000',
          redirect_uri: process.env.REACT_APP_BASE_URL,
          audience: `${process.env.REACT_APP_AUTH0_URL}/api/v2/`,
          audience: 'https://api.allowancetrack.com',
          scope: 'openid profile email offline_access',
        }}
        useRefreshTokens={true}
        cacheLocation='localstorage'
        useRefreshTokensFallback={true}
      >
        <AllowanceProvider>
          <App />
        </AllowanceProvider>
      </Auth0Provider>
    </Router>
  </React.StrictMode>
);
