import { useAuth0 } from '@auth0/auth0-react';
import Button from '../shared/Button';
import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import { AllowanceContext } from '../../context/AllowanceContext';
import UserDropDown from './UserDropDown';

const Menu = ({ children, iconClassname }) => {
  const {
    loginWithRedirect,
    logout,
    user,
    isAuthenticated,
    isLoading: auth0Loading,
  } = useAuth0();

  const {
    checkout,
    createModal,
    user: ctxtusr,
    baseUrl,
  } = useContext(AllowanceContext);

  return (
    <>
      {/* Toggle Button
            Button to show navbar
          */}
      <div className='my-auto'>
        {!auth0Loading &&
          (!isAuthenticated ? (
            <>
              <Link to='/about' style={{ cursor: 'pointer' }}>
                <Button
                  color='outline-info'
                  className='mx-3 text-decoration-none hover-white'
                >
                  About
                </Button>
              </Link>
              <Button
                color='success'
                className='btn-1 bg-animated-b-g'
                data-bs-toggle='modal'
                data-bs-target='#modal'
                onClick={() =>
                  createModal(
                    'Choose a plan!',
                    'PRICING',
                    <>
                      <Button
                        outline
                        color='primary'
                        onClick={() => {
                          loginWithRedirect({
                            authorizationParams: {
                              screen_hint: 'signup',
                              redirect_uri: `${baseUrl}`,
                            },
                          });
                        }}
                      >
                        Free Plan
                      </Button>
                      <Button
                        color='success'
                        className='bg-animated-b-g'
                        onClick={() => {
                          loginWithRedirect({
                            authorizationParams: {
                              screen_hint: 'signup',
                              redirect_uri: `${baseUrl}/billing`,
                            },
                          });
                        }}
                      >
                        Plus Plan
                      </Button>
                    </>,
                    false
                  )
                }
              >
                Sign Up
              </Button>
              <Button
                outline
                color='primary'
                className='mx-3 btn-2'
                onClick={() => loginWithRedirect()}
              >
                Log In
              </Button>
            </>
          ) : (
            <>
              {/*{ctxtusr && ctxtusr.plan == 'paid' &&*/} <UserDropDown />
              {ctxtusr && ctxtusr.plan == 'free' && (
                <Button
                  color='primary'
                  size='md'
                  className='d-inline bg-animated-b-g'
                  style={{
                    marginLeft: '1rem',
                  }}
                  As={Link}
                  to='/billing'
                >
                  Upgrade
                </Button>
              )}
              {user && (
                <div className='btn-group dropdown'>
                  {/* <Link to='/settings'> */}
                  <img
                    src={user.picture}
                    width='40'
                    height='40'
                    alt={user.nickname}
                    className='btn btn-img btn-1 dropdown-toggle mx-3'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                    style={{
                      borderRadius: '9999px',
                      padding: '0',
                    }}
                  />
                  {/* </Link> */}
                  <ul
                    className='dropdown-menu dropdown-menu-end shadow'
                    id='dropdown-profile'
                  >
                    <li>
                      <Link
                        to='/settings'
                        style={{ cursor: 'pointer' }}
                        className='dropdown-item mx-3 rounded'
                      >
                        Settings
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='legal/terms'
                        style={{ cursor: 'pointer' }}
                        className='dropdown-item mx-3 rounded'
                      >
                        Terms of Use
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/support'
                        style={{ cursor: 'pointer' }}
                        className='dropdown-item mx-3 rounded'
                      >
                        Support
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/about'
                        style={{ cursor: 'pointer' }}
                        className='dropdown-item mx-3 rounded'
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <a
                        href='/blog'
                        style={{ cursor: 'pointer' }}
                        className='dropdown-item mx-3 rounded'
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <Link
                        to='/'
                        style={{ cursor: 'pointer' }}
                        className='dropdown-item mx-3 rounded'
                      >
                        Home
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to='legal/privacy'
                        style={{ cursor: 'pointer' }}
                        className='dropdown-item mx-3 rounded'
                      >
                        Privacy Policy
                      </Link>
                    </li> */}
                    <li>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => logout()}
                        className='dropdown-item logout mx-3 rounded'
                      >
                        Sign Out
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </>
          ))}
        {/* <Button
          className='navbar-toggler my-auto'
          color='light'
          data-bs-toggle='offcanvas'
          data-bs-target='#offcanvasNavbar'
          aria-controls='offcanvasNavbar'
        >
          <i
            className={iconClassname ? iconClassname : 'fa-solid fa-navicon'}
          />
        </Button> */}
      </div>

      {/* End of Toggle Button */}
      {/* Navbar
          Bar for switching between charts and home
        */}
      <div
        className='offcanvas offcanvas-end'
        tabIndex={-1}
        id='offcanvasNavbar'
        aria-labelledby='offcanvasNavbarLabel'
      >
        <div className='offcanvas-header'>
          <h3 className='offcanvas-title' id='offcanvasNavbarLabel'>
            Allowance Track
          </h3>
          {/* X Button */}
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          />
          {/* End of X Button */}
        </div>
        <div className='offcanvas-body'>
          <ul className='navbar-nav justify-content-end flex-grow-1 pe-3'>
            {!isAuthenticated ? (
              <>
                <li>
                  <Button
                    color='success'
                    className='btn-3'
                    onClick={() =>
                      loginWithRedirect({
                        authorizationParams: {
                          screen_hint: 'signup',
                          redirect_uri: `${baseUrl}/?signup=true`,
                        },
                      })
                    }
                  >
                    Sign Up
                  </Button>
                </li>
                <li>
                  <Button
                    outline
                    color='primary'
                    className='my-3 btn-4'
                    onClick={() => loginWithRedirect()}
                  >
                    Log In
                  </Button>
                </li>
              </>
            ) : (
              <>
                <li>
                  {user && (
                    <Link to='/settings'>
                      <img
                        src={user.picture}
                        width='40'
                        height='40'
                        alt={user.nickname}
                        className='btn btn-img btn-3'
                        style={{
                          borderRadius: '9999px',
                          padding: '0',
                        }}
                      />
                    </Link>
                  )}
                </li>
                <li>
                  <Button
                    color='danger'
                    onClick={() => logout()}
                    className='my-3 btn-4'
                  >
                    Sign Out
                  </Button>
                </li>
              </>
            )}
          </ul>
          <hr style={{ marginTop: '0' }} className='media-hr' />
          {children}
        </div>
      </div>
      {/* End of Navbar */}
    </>
  );
};

export default Menu;
