import React from 'react';
import image from '../assets/bio.jpg';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

function BioCard() {
  return (
    <>
      <Helmet>
        <title>Allowance Track | About</title>
        <meta name='title' content='Allowance Track | About'></meta>
        <meta
          name='description'
          content="Discover the ultimate allowance tracker! Allowance Track empowers parents to effortlessly oversee and manage their kids' allowances with a user-friendly platform. Engage in setting tasks, tracking progress, and rewarding achievements. Start simplifying your financial parenting journey today!"
        ></meta>
        <meta
          name='keywords'
          content='allowance tracker app, allowance tracker, allowance tracking, children allowance management, family finance tool, kids money management, task rewards system, financial literacy for kids, parental control finance, digital allowance system, child allowance app, manage kids money, financial planning for kids, allowance tracking software'
        ></meta>
        <meta property='og:title' content='Allowance Track | About' />
        <meta
          property='og:description'
          content="Discover the ultimate allowance tracker! Allowance Track empowers parents to effortlessly oversee and manage their kids' allowances with a user-friendly platform. Engage in setting tasks, tracking progress, and rewarding achievements. Start simplifying your financial parenting journey today!"
        ></meta>
        <meta property='og:type' content='website' />
        <meta property='og:url' content={`${process.env.REACT_APP_BASE_URL}`} />
        <meta property='og:image' content={image}></meta>
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content='Allowance Track | About' />
        <meta
          name='twitter:description'
          content="Discover the ultimate allowance tracker! Allowance Track empowers parents to effortlessly oversee and manage their kids' allowances with a user-friendly platform. Engage in setting tasks, tracking progress, and rewarding achievements. Start simplifying your financial parenting journey today!"
        />
        <meta name='twitter:image' content={image}></meta>
      </Helmet>
      <motion.div
        initial={{ opacity: '0' }}
        animate={{ opacity: '1' }}
        className='w-100 d-flex'
        id='bio'
      >
        <img
          src={image}
          alt='Profile'
          className='mr-3'
          style={{
            width: '38%',
            height: '80%',
            justifySelf: 'center',
            position: 'fixed',
            objectFit: 'cover',
            objectPosition: 'center 20px center',
            borderBottomRightRadius: '5rem',
            opacity: '.7',
          }}
          id='bio-img'
        />
        <div
          className='card'
          style={{
            width: '61%',
            marginLeft: '39%',
            // borderRadius: '0rem',
          }}
          id='bio-card'
        >
          <div
            className='card-body p-5 v-cont'
            style={{ textAlign: 'left' }}
            id='bio-text'
          >
            <h5 className='card-title fs-2 v-target'>
              Aden - The Creator of Allowance Track
            </h5>
            <p className='card-text'>
              At only 9 years old and in the fourth grade, Aden became very
              interested in building his own full-stack web applications while
              in elementary school in the Atlanta metropolitan area. Fully self
              taught in his free time, he learned full-stack coding and mastered
              front and back-end development as well as machine learning and
              artificial intelligence.
              <br />
              <br />
              He developed and built Allowance Track on his own from the ground
              up to help kids around the world track their chores and allowance
              earnings, and learn to stay disciplined and focused on their
              goals. In addition to coding, Aden loves soccer, jui jitsu,
              kickboxing, and playing piano.
              <br />
              <br />
              Here are some of the technologies he loves to work with: HTML,
              CSS, Javacript, React, Node.JS, Python, Tailwind, Webhooks, API
              development and integration, machine learning, OpenAI integration,
              ML model development, training, and inferencing, neural network
              development, Payment gateway implementation, Stripe development,
              SMTP server integration, Cloud solutions, Azure, MongoDb,
              SQL/No-SQL databases.
              <br />
              <br />
              Also, check out another AI enhanced web app that Aden created at{' '}
              <a
                href='http://acronotes.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                acronotes.com
              </a>
            </p>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default BioCard;
