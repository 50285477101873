import Home from './pages/Home';
import Navbar from './components/layout/Navbar';
import { Routes, Route, Outlet, useLocation } from 'react-router-dom';
import spinner from './assets/wired-gradient-105-loader-1 (1)-min.gif';
import ComingSoon from './pages/ComingSoon';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { AllowanceContext } from './context/AllowanceContext';
import Button from './components/shared/Button';
import Protected from './components/routing/ProtectedRoute';
import Profile from './pages/Profile';
import ProfileSelect from './pages/ProfileSelect';
import Billing from './pages/Billing';
// import './css/sketchy.min.css';
import Loader from './components/shared/Loader';
import Landing from './pages/Landing';
import NotFound from './pages/NotFound';
import { Helmet } from 'react-helmet';
import Support from './pages/Support';
import TermsOfService from './pages/TermsOfService';
import Card from './components/shared/Card';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Legal from './pages/Legal';
import image from './assets/Allowance Track - Best Chore Tracking App.jpg';
import About from './pages/About';
import ReactGA from 'react-ga4';
import { AnimatePresence, motion } from 'framer-motion';
import Admin from './pages/Admin';
import { DateObject } from 'react-multi-date-picker';
import Verify from './pages/Verify';

const SpinnerLogin = ({ signup, plus }) => {
  const { baseUrl } = useContext(AllowanceContext);
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    loginWithRedirect(
      signup
        ? plus
          ? {
              authorizationParams: {
                screen_hint: 'signup',
                redirect_uri: `${process.env.REACT_APP_BASE_URL}/billing`,
              },
            }
          : {
              authorizationParams: {
                screen_hint: 'signup',
                redirect_uri: process.env.REACT_APP_BASE_URL,
              },
            }
        : undefined
    );
  });
  return (
    <>
      <Helmet>
        <title>{signup ? 'Sign up' : 'Log in'} | Allowance Track</title>
      </Helmet>
      <img
        src={spinner}
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
        }}
        width='400'
        height='400'
      />
    </>
  );
};

function App() {
  const {
    modal: {
      title: modalTitle,
      body: modalBody,
      buttons: modalFooter,
      persistant: modalPersistant,
      scroll: modalScrollable,
    },
    tokenExpired,
    loading,
    overlay,
    setOverlay,
    createModal,
    showNav,
    hideNav,
    showNavBar,
    addTask,
    setEarnable,
    fetchTasksFromDB,
    setLoading,
    week: { str: week },
    taskExists,
    tasks,
    updateChecked,
    kids,
    can,
    tasksLoaded,
    user: ctxUser,
    getWeekStartAndEndDates,
    billing,
    alert,
    createAlert,
    removeAlert,
  } = useContext(AllowanceContext);
  const {
    loginWithRedirect,
    isLoading,
    isAuthenticated,
    user: auth0user,
  } = useAuth0();

  const [prevActive, setPrevActive] = useState(null);
  const [showHome, setShowHome] = useState(false);
  const [, reRender] = useState();

  const { pathname } = useLocation();
  const location = useLocation();

  useEffect(() => {
    const lsTasks = localStorage.getItem('tasks');
    can(() => JSON.parse(lsTasks).length > 0, true) && setShowHome(true);
    document.body.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant', // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  if (tokenExpired && isAuthenticated && !loading) {
    loginWithRedirect();
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const lstasks = localStorage.getItem('tasks');
        const perweek = localStorage.getItem('perWeek');
        if (isAuthenticated && !isLoading) {
          if (lstasks) {
            setLoading(true);
            for (const task of JSON.parse(lstasks)) {
              await (async () => {
                setLoading(true);

                if (!taskExists(task.task)) {
                  setLoading(true);

                  const id = await addTask(task.task, [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                  ]);

                  await updateChecked(task.checkStates, id);

                  setLoading(true);
                }
              })();
            }

            localStorage.removeItem('tasks');
          }

          if (perweek) {
            setLoading(true);

            await setEarnable(perweek);
            localStorage.removeItem('perWeek');
          }
        } else {
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);

        reRender();
      }
    })();
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    (async () => {
      try {
        if (
          (!prevActive ||
            (kids &&
              prevActive !== kids.active &&
              kids.active &&
              !kids.active.loading)) &&
          isAuthenticated &&
          !isLoading
        ) {
          setLoading(true);

          const tasks = await fetchTasksFromDB(week);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setPrevActive(kids.active);
      }
    })();
  }, [isAuthenticated, isLoading, kids]);

  useEffect(() => {
    const fetchDataAndSetLoading = async () => {
      const tasks = await fetchTasksFromDB(
        getWeekStartAndEndDates(
          new DateObject().subtract(1, 'day').format('YYYY-MM-DD')
        ).str
      );
      console.log('tasks:', tasks);
      if (tasks.length == 0) {
        await fetchTasksFromDB('prev', week);
        createAlert(
          "We've added your previous week's tasks to this week.",
          'success',
          7500
        );
      }
    };
    isAuthenticated &&
      !isLoading &&
      ctxUser &&
      ctxUser.plan &&
      !tasksLoaded &&
      fetchDataAndSetLoading();
  }, [isAuthenticated, isLoading, ctxUser]);

  // ReactGA.initialize('G-1H3HSMM2BJ');

  // useEffect(() => {
  //   // Send pageview with a custom path
  //   ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  // }, [window.location]);
  const queryParams = new URLSearchParams(location.search);

  const hasSupportSignUp = queryParams.has('supportSignUp');
  const hasSupportForgotPassword = queryParams.has('supportForgotPassword');
  const hasMessage = queryParams.has('message');
  const hasSuccess = queryParams.has('success');
  const hasCode = queryParams.has('code');

  useEffect(() => {
    if (
      hasSupportSignUp &&
      hasSupportForgotPassword &&
      hasMessage &&
      hasSuccess &&
      hasCode &&
      !isLoading &&
      auth0user &&
      auth0user.sub
    ) {
      loginWithRedirect(
        billing
          ? {
              authorizationParams: {
                redirect_uri: `${process.env.REACT_APP_BASE_URL}/billing`,
              },
            }
          : undefined
      );
    }
  }, [
    hasSupportSignUp,
    hasSupportForgotPassword,
    hasMessage,
    hasSuccess,
    hasCode,
    isLoading,
    auth0user,
  ]);

  return (
    <>
      <Helmet>
        <title>Allowance Track</title>
        <meta
          name='title'
          content="Allowance Track - Manage Kids' Allowances Easily"
        ></meta>
        <meta
          name='description'
          content="Allowance Track helps parents track and manage their kids' allowances based on chosre completion. Set chores, monitor progress, and reward your children with our intuitive interface!"
        ></meta>
        <meta
          name='keywords'
          content='allowance, kids, tasks, rewards, money management, track allowances, parental tool, financial responsibility, child tasks, allowance manager'
        ></meta>
        <meta
          name='keywords'
          content='allowance, kids, tasks, rewards, money management, track allowances, parental tool, financial responsibility, child tasks, allowance manager'
        ></meta>
        <meta
          property='og:title'
          content="Allowance Track - Manage Kids' Allowances Easily"
        />
        <meta
          property='og:description'
          content="Allowance Track helps parents track and manage their kids' allowances based on chore completion. Set chores, monitor progress, and reward your children with our intuitive interface!"
        ></meta>
        <meta property='og:type' content='website' />
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_BASE_URL}${window.location.pathname}`}
        />
        <meta property='og:image' content={image}></meta>
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:title'
          content="Allowance Track - Manage Kids' Allowances Easily"
        />
        <meta
          name='twitter:description'
          content="Allowance Track helps parents track and manage their kids' allowances based on chore completion. Set chores, monitor progress, and reward your children with our intuitive interface!"
        />
        <meta name='twitter:image' content={image}></meta>
      </Helmet>
      {showNavBar && <Navbar />}

      <div
        className='modal fade'
        id='staticBackdropModal'
        data-bs-backdrop={'static'}
        data-bs-keyboard={'false'}
        tabIndex='-1'
        aria-labelledby={'staticBackdropLabel'}
        aria-hidden='false'
        style={{
          zIndex: '99999999999999999999999999999999999999999999',
        }}
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                {modalTitle}
              </h1>
            </div>
            <div className='modal-body'>{modalBody}</div>
            <div className='modal-footer'>{modalFooter}</div>
          </div>
        </div>
      </div>
      <>
        <div
          className='modal fade'
          id='modal'
          tabIndex='-1'
          aria-hidden='true'
          style={{
            zIndex: '99999999999999999999999999999999999999999999',
          }}
        >
          <div
            className={`modal-dialog modal-dialog-centered ${
              modalScrollable ? 'modal-dialog-scrollable' : ''
            }`}
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                  {modalTitle}
                </h1>
                <Button
                  color='close'
                  data-bs-toggle='modal'
                  data-bs-target='#modal'
                >
                  {''}
                </Button>
              </div>
              <div className='modal-body'>{modalBody}</div>
              <div className='modal-footer'>{modalFooter}</div>
            </div>
          </div>
        </div>
        <AnimatePresence>
          {overlay && (
            <motion.div
              key={'overlay'}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 0.5,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{
                duration: 0.25,
              }}
              className='modal-backdrop fade show'
            ></motion.div>
          )}
          {overlay == 'modal' && (
            <motion.div
              className='modal fade show'
              initial={{
                y: -50,
                opacity: 0,
              }}
              animate={{
                y: 0,
                opacity: 1,
              }}
              exit={{
                y: -50,
                opacity: 0,
              }}
              transition={{
                duration: 0.25,
                delay: 0.125,
                // ease: '',
              }}
              id='modal'
              tabIndex='-1'
              aria-hidden='true'
              style={{
                zIndex: '99999999999999999999999999999999999999999999',
              }}
              onClick={(e) => {
                setOverlay(false);
              }}
            >
              <div
                className='modal-dialog modal-dialog-centered'
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                      {modalTitle}
                    </h1>
                    <Button color='close' onClick={() => setOverlay(false)}>
                      {''}
                    </Button>
                  </div>
                  <div className='modal-body'>{modalBody}</div>
                  <div className='modal-footer'>{modalFooter}</div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>

      <AnimatePresence>
        {!alert.hidden && (
          <motion.div
            initial={{ opacity: 0, x: 500 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 500 }}
            className={`alert alert-${alert.type}`}
            role='alert'
            style={{
              zIndex: '1055',
              maxWidth: '75%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <i
              className={
                alert.type === 'success'
                  ? 'fas fa-check'
                  : alert.type === 'danger' || 'warning'
                  ? 'fa-solid fa-triangle-exclamation'
                  : ''
              }
              style={{ marginLeft: '0.5rem' }}
            ></i>
            &nbsp;&nbsp;&nbsp;
            {alert.msg}
            <button
              type='button'
              className='btn-close'
              aria-label='Close'
              onClick={() => removeAlert()}
              style={{ marginRight: '0.5rem' }}
            ></button>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {(isLoading || !tasksLoaded) && (
          <motion.img
            src={spinner}
            initial={{ opacity: '0' }}
            animate={{ opacity: '1' }}
            ext={{ opacity: '0' }}
            key={1}
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
            }}
            width='400'
            height='400'
          />
        )}
      </AnimatePresence>
      {!isLoading &&
        ctxUser &&
        !ctxUser?.email_verified &&
        !queryParams.has('show') &&
        process.env.REACT_APP_EMAIL_VERIFICATION && <Verify />}
      {/* {true && !queryParams.has('show') && <Verify />} */}

      {!(isLoading || !tasksLoaded) && (
        <Routes>
          <Route
            path='/'
            element={
              isAuthenticated /*|| window.location.hostname == 'localhost'*/ ? (
                <Home />
              ) : (
                <Landing />
              )
            }
          />
          {/* <Route path='/' element={<Home />} /> */}
          <Route path='/support' element={<Support />} />
          <Route path='*' element={<NotFound />} />
          {/* <Route path='charts' element={<ComingSoon />} /> */}
          <Route path='/login' element={<SpinnerLogin />} />
          <Route path='/signup' element={<SpinnerLogin signup />} />
          <Route path='/signup/plus' element={<SpinnerLogin signup plus />} />
          {/* <Route path='/profile-select' element={<ProfileSelect />} /> */}
          <Route path='/billing' element={<Billing />} />
          <Route path='/about' element={<About />} />
          <Route
            path='/admin'
            element={ctxUser?.plan === 'admin' ? <Admin /> : <NotFound />}
          />
          {/* <Route
          path='/legal'
          element={
            <Card
              className='m-md-5 p-md-5 p-3 py-5 m-3 min-h-custom'
              style={{ textAlign: 'left' }}
            >
              <Outlet />
            </Card>
          }
        >
          {/* <Route path='' element={<Legal />} />
          <Route path='terms' element={<TermsOfService />} /> */}
          {/*<Route path='privacy' element={<PrivacyPolicy />} />
        </Route> */}
          <Route
            path='/legal/terms'
            element={
              <motion.div initial={{ opacity: '0' }} animate={{ opacity: '1' }}>
                <Card
                  className='m-md-5 p-md-5 p-3 py-5 m-3 min-h-custom'
                  style={{ textAlign: 'left' }}
                >
                  <TermsOfService />
                </Card>
              </motion.div>
            }
          />
          <Route path='/' element={<Protected />}>
            <Route path='/settings' element={<Profile />} />
          </Route>
        </Routes>
      )}
    </>
  );
}

export default App;
