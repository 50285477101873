import { forwardRef } from 'react';

const Card = forwardRef(({ className, children, As, ...props }, ref) => {
  if (As) {
    return (
      <As className={`card shadow ${className}`} {...props}>
        {children}
      </As>
    );
  } else {
    return (
      <div ref={ref} className={`card shadow ${className}`} {...props}>
        {children}
      </div>
    );
  }
});

export default Card;
