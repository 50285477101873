import Card from '../components/shared/Card';
import Button from '../components/shared/Button';
import { useContext, useEffect, useRef, useState } from 'react';
import { AllowanceContext } from '../context/AllowanceContext';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

const Profile = () => {
  const { user: auth0user } = useAuth0();
  const { user, createModal, resetPassword, getAccessToken } =
    useContext(AllowanceContext);

  const [invalid, setInvalid] = useState(false);
  const [edit, setEdit] = useState(true);
  const [nameVal, setNameVal] = useState('');

  const inpRef = useRef(null);

  useEffect(() => {
    setNameVal(auth0user.nickname);
  }, [auth0user]);

  return (
    <motion.div
      initial={{ opacity: '0' }}
      animate={{ opacity: '1' }}
      className='p-3 profile-page'
      style={{ textAlign: 'left' }}
    >
      <Helmet>
        <title>Settings | Allowance Track</title>
        <meta name='title' content='Settings | Allowance Track'></meta>
        <meta
          name='description'
          content='Account related settings and info'
        ></meta>
        <meta
          name='keywords'
          content='allowance, kids, tasks, rewards, money management, track allowances, parental tool, financial responsibility, child tasks, allowance manager, settings'
        ></meta>
        <meta property='og:title' content='Settings | Allowance Track' />
        <meta
          property='og:description'
          content='Account related settings and info'
        ></meta>
        <meta property='og:type' content='website' />
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_BASE_URL}/settings`}
        />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content='Settings | Allowance Track' />
        <meta
          name='twitter:description'
          content='Account related settings and info'
        />
      </Helmet>
      <Card className='p-5'>
        <div
          className='user-cont'
          style={{
            height: '100px',
          }}
        >
          <img
            src={auth0user && auth0user.picture}
            alt=''
            className='rounded profile-img'
            width={100}
            style={{ display: 'inline', marginTop: '-22px' }}
            height={100}
          />
          <h1
            className='display-3'
            style={{ display: 'inline', marginLeft: '20px' }}
          >
            {auth0user && auth0user.nickname}
          </h1>
        </div>
        <ul
          className='list-group profile-list-group'
          style={{ marginTop: '50px' }}
        >
          <li className='list-group-item p-3'>
            <h5>Plan:</h5>
            <div className='input-group'>
              <input
                className='form-control d-inline sub-name'
                type='text'
                readOnly={true}
                style={{
                  width: '75%',
                  marginBottom: '0',
                }}
                value={user && user.plan == 'free' ? 'Free' : 'Plus'}
              />
              {user && user.plan == 'free' ? (
                <>
                  <Button
                    color='primary'
                    size='md'
                    className='d-inline sub-btn bg-animated-b-g'
                    style={{
                      width: '25%',
                    }}
                    As={Link}
                    to='/billing'
                  >
                    Upgrade Plan
                  </Button>
                </>
              ) : (
                <Button
                  color='primary'
                  size='md'
                  className='d-inline sub-btn'
                  style={{
                    width: '25%',
                  }}
                  As={Link}
                  to='/billing'
                >
                  Manage Subscription
                </Button>
              )}
            </div>
          </li>
          {/* <li className='list-group-item p-3'>
            <h5>Name:</h5>
            <form
              action='#'
              id='money-form'
              onSubmit={async (e) => {
                e.preventDefault();
                setEdit(!edit);
                const token = await getAccessToken({
                  authorizationParams: {
                    audience: `https://login.auth0.com/api/v2`,
                    scope: 'openid profile email offline_access',
                  },
                });
                if (token) {
                  await axios.patch(
                    'https://login.auth0.com/api/v2/users/:id',
                    { nickname: nameVal },
                    {
                      headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        authorization: `Bearer ${token}`,
                      },
                    }
                  );
                } else {
                  
                }
              }}
              noValidate={true}
            >
              <div
                className={`input-group input-group-media input-group-sm has-validation`}
              >
                <input
                  type='email'
                  min={1}
                  readOnly={edit}
                  // onClick={() => setEdit(false)}
                  onChange={(e) => {
                    
                    
                    setNameVal(e.target.value);
                  }}
                  className={`form-control ${invalid ? 'is-invalid' : ''}`}
                  style={{
                    borderTopRightRadius: '0',
                    borderBottomRightRadius: '0',
                  }}
                  id='per-week'
                  value={nameVal}
                  ref={inpRef}
                  step='any'
                  required
                />
                <Button
                  className={`${
                    edit || edit === null
                      ? 'btn-light-info-not-hover'
                      : 'btn-light-success-not-hover'
                  }`}
                  outline
                  color={!edit && edit !== null ? 'success' : 'info'}
                  onClick={() => {
                    setTimeout(() => edit && inpRef.current.focus(), '1');
                  }}
                  style={{ border: 'none' }}
                  type='submit'
                  id='submit-3'
                >
                  {edit || edit === null ? (
                    <i className='fa-solid fa-pen'></i>
                  ) : (
                    <i className='fa-solid fa-check'></i>
                  )}
                </Button>
              </div>
            </form>
          </li> */}
          <li className='list-group-item p-3'>
            <h5>Email:</h5>
            <input
              className='form-control'
              type='text'
              disabled={true}
              value={user ? user.email : ''}
            />
          </li>
          <li className='list-group-item p-3'>
            <Button
              color='danger'
              size='md'
              className='mx-auto d-block'
              data-bs-toggle='modal'
              data-bs-target='#modal'
              onClick={() =>
                createModal(
                  'Are you sure you want to reset your password?',
                  <>
                    <p style={{ textAlign: 'left' }}>
                      If you continue, you will not be able to log in again with
                      your old password. An email will be sent with a password
                      reset link.
                    </p>
                  </>,
                  <>
                    <Button
                      color='secondary'
                      data-bs-toggle='modal'
                      data-bs-target='#modal'
                    >
                      Cancel
                    </Button>
                    <Button color='danger' onClick={() => resetPassword()}>
                      Confirm
                    </Button>
                  </>
                )
              }
            >
              Reset Password
            </Button>
          </li>
        </ul>
      </Card>
    </motion.div>
  );
};

export default Profile;
