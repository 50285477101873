import { useContext, useEffect, useRef, useState } from 'react';
import Button from '../shared/Button';
import { AllowanceContext } from '../../context/AllowanceContext';

const UserDropItem = ({ kidName, id, active, addProfile, loading }) => {
  const {
    addKid,
    setActiveKid,
    updateKid,
    removeKid,
    kids: { list: kidList },
    kidExists,
    createAlert,
  } = useContext(AllowanceContext);
  const [disabled, setDisabled] = useState(true);
  const [value, setValue] = useState(kidName ? kidName : '');
  const inpRef = useRef(null);
  return addProfile ? (
    kidList.length < 4 ? (
      <Button
        As='li'
        color='light'
        style={{
          cursor: 'pointer',
          background: active ? 'rgba(var(--bs-dark-rgb),0.2)' : undefined,
        }}
        onClick={(e) => {
          e.stopPropagation();
          setDisabled(disabled ? false : true);
          setTimeout(() => inpRef.current && inpRef.current.focus(), 100);
        }}
        className={`dropdown-item mx-3 rounded d-flex vert-cont p-0 my-1 add-kid-green`}
      >
        <form
          action='#'
          style={{ width: '100%' }}
          onSubmit={(e) => {
            e.preventDefault();
            if (kidExists(value)) {
              createAlert(`A kid named ${value} already exists`, 'danger');
              setDisabled(false);
              e.stopPropagation();
            } else if (value == '' || !value) {
              createAlert(`Please enter a name`, 'danger');
              setDisabled(false);
              e.stopPropagation();
            } else {
              setDisabled(true);
              addKid(value);
              setValue('');
            }
          }}
        >
          <div
            className='input-group'
            style={{
              background: 'transparent',
              width: '100%',
              flexWrap: 'nowrap',
            }}
          >
            {!disabled ? (
              <input
                type='text'
                className='form-control af-bg-white'
                style={{
                  border: 'none',
                  background: 'transparent',
                }}
                ref={inpRef}
                onClick={(e) => e.stopPropagation()}
                onBlur={(e) => {
                  if (!e.relatedTarget || !e.relatedTarget.id) {
                    setTimeout(() => setDisabled(true), 100);
                  }
                  if (
                    e.relatedTarget &&
                    e.relatedTarget.id &&
                    e.relatedTarget.id !== `profile-add`
                  ) {
                    setDisabled(true);
                  }
                }}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={disabled}
              />
            ) : (
              <div
                style={{
                  display: 'inline-block',
                  maxWidth: 'calc(250px - (37px) - 2rem)',
                  minWidth: 'calc(250px - (37px) - 2rem)',
                  padding: disabled ? '0.375rem 0.75rem' : undefined,
                  paddingRight: '0',
                  textOverflow: 'ellipsis',
                  overflow: 'clip',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                }}
              >
                Add Kid
              </div>
            )}

            <Button
              className=''
              id={`profile-add`}
              color={disabled ? 'secondary' : 'success'}
              outline={disabled}
              style={{ border: 'none', width: '36px' }}
              type={!disabled ? 'button' : 'submit'}
            >
              <i className={`fas fa-circle-${disabled ? 'plus' : 'check'}`}></i>
            </Button>
          </div>
        </form>
      </Button>
    ) : null
  ) : (
    <Button
      As='li'
      color='light'
      style={{
        cursor: 'pointer',
        // background: active ? 'rgba(var(--bs-dark-rgb),0.2)' : undefined,
      }}
      onClick={() => {
        setActiveKid(id);
      }}
      className={`dropdown-item mx-3 rounded d-flex vert-cont p-0 my-1 ${
        active ? 'active' : ''
      } ${loading && 'placeholder-wave'}`}
    >
      <form
        action='#'
        style={{ width: '100%' }}
        onSubmit={(e) => {
          e.preventDefault();
          if (!disabled) {
            if (kidExists(value) && value !== kidName) {
              createAlert(`A kid named ${value} already exists`, 'danger');
            } else if (value == '' || !value) {
              createAlert(`Please enter a name`, 'danger');
            } else {
              setDisabled(!disabled);
              updateKid({ kidName: value, id });
              setTimeout(
                () => disabled && inpRef.current && inpRef.current.focus(),
                100
              );
            }
          } else {
            setDisabled(!disabled);
            setTimeout(
              () => disabled && inpRef.current && inpRef.current.focus(),
              100
            );
          }
        }}
        className={loading ? 'placeholder' : ''}
      >
        <div
          className='input-group'
          style={{
            background: 'transparent',
            width: '100%',
            flexWrap: 'nowrap',
          }}
        >
          {!disabled ? (
            <input
              type='text'
              className='form-control af-bg-white'
              // size={10000}
              style={{
                maxWidth: `calc(250px - (37px * ${
                  kidList.length > 1 ? '2' : '1'
                }) - 2rem)`,
                minWidth: `calc(250px - (37px * ${
                  kidList.length > 1 ? '2' : '1'
                }) - 2rem)`,
                border: 'none',
                background: 'transparent',
              }}
              ref={inpRef}
              onBlur={(e) => {
                if (!e.relatedTarget || !e.relatedTarget.id) {
                  setTimeout(() => setDisabled(true), 100);
                }
                if (
                  e.relatedTarget &&
                  e.relatedTarget.id &&
                  e.relatedTarget.id !== `profile-edit-${id}`
                ) {
                  setDisabled(true);
                }
              }}
              onClick={(e) => e.stopPropagation()}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              disabled={disabled}
            />
          ) : (
            <div
              className='form-control'
              style={{
                display: 'inline-block',
                maxWidth: `calc(250px - (37px * ${
                  kidList.length > 1 ? '2' : '1'
                }) - 2rem)`,
                minWidth: `calc(250px - (37px * ${
                  kidList.length > 1 ? '2' : '1'
                }) - 2rem)`,
                padding: disabled ? '0.375rem 0.75rem' : undefined,
                textOverflow: 'ellipsis',
                overflow: 'clip',
                whiteSpace: 'nowrap',
                textAlign: 'left',
                backgroundColor: 'transparent',
                border: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column',
              }}
            >
              {kidName}
            </div>
          )}

          <Button
            id={`profile-edit-${id}`}
            className='p-2'
            color={disabled ? 'secondary' : 'success'}
            outline={disabled}
            style={{
              border: 'none',
              width: '36.34px',
              display: loading && 'none',
            }}
            onClick={(e) => e.stopPropagation()}
            type='submit'
          >
            {disabled ? (
              <i className='fas fa-pen'></i>
            ) : (
              <i className='fas fa-check'></i>
            )}
          </Button>
          {kidList.length > 1 && (
            <Button
              className={`p-2 not-hover-bg-transparent  placeholder`}
              id={`profile-delete-${id}`}
              color='danger'
              outline
              style={{
                border: 'none',
                width: '37px',
                display: loading ? 'none' : undefined,
                opacity: 1,
              }}
              onClick={(e) => {
                e.stopPropagation();
                removeKid(id);
              }}
              type='button'
            >
              <i className='fas fa-xmark'></i>
            </Button>
          )}
        </div>
      </form>
    </Button>
  );
};
export default UserDropItem;
