import PropTypes from 'prop-types';

const Button = ({
  children,
  outline,
  color,
  size,
  className,
  type,
  As,
  ...props
}) => {
  if (As) {
    return (
      <As
        className={`btn btn-${
          outline
            ? `outline-${color ? color : 'primary'}`
            : color
            ? color
            : 'primary'
        } ${size ? `btn-${size}` : ''} ${className}`}
        {...props}
      >
        {children}
      </As>
    );
  } else {
    return (
      <button
        type={type ? type : 'button'}
        className={`btn btn-${
          outline
            ? `outline-${color ? color : 'primary'}`
            : color
            ? color
            : 'primary'
        } ${className}`}
        {...props}
      >
        {children}
      </button>
    );
  }
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  type: PropTypes.string,
  outline: PropTypes.bool,
  color: PropTypes.string,
  as: PropTypes.element,
};
export default Button;
