import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AllowanceContext } from '../context/AllowanceContext';
import { Navigate } from 'react-router-dom';
import spinner from '../assets/wired-gradient-105-loader-1 (1)-min.gif';
import Button from '../components/shared/Button';

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [activeFilter, setActiveFilter] = useState('');
  const [sortOption, setSortOption] = useState('');
  const { isAuthenticated } = useAuth0();
  const {
    fetchAdmin,
    getAccessToken,
    user: ctxUser,
    api,
    createModal,
    setOverlay,
  } = useContext(AllowanceContext);

  useEffect(() => {
    const fetchUsers = async () => {
      const token = await getAccessToken();
      if (token) {
        const fetchedUsers = await fetchAdmin();
        setUsers(fetchedUsers);
        setFilteredUsers(fetchedUsers);
      }
    };

    if (isAuthenticated) {
      fetchUsers();
    }
  }, [isAuthenticated, getAccessToken, fetchAdmin]);

  useEffect(() => {
    const sortUsers = (option) => {
      let sortedUsers = [...filteredUsers];
      switch (option) {
        case 'userIdDesc':
          sortedUsers.sort((a, b) => b.userId - a.userId);
          break;
        case 'lastActivityDesc':
          sortedUsers.sort(
            (a, b) =>
              new Date(b.last_activity_timestamp) -
              new Date(a.last_activity_timestamp)
          );
          break;
        case 'taskCountDesc':
          sortedUsers.sort((a, b) => b.task_count - a.task_count);
          break;
        default:
          break;
      }
      setFilteredUsers(sortedUsers);
    };

    if (sortOption) {
      sortUsers(sortOption);
    }
  }, [sortOption, filteredUsers]);

  const applyFilter = (plan) => {
    const filtered = users.filter((user) => user.plan === plan);
    setFilteredUsers(filtered);
    setActiveFilter(plan);
  };

  const clearFilter = () => {
    setFilteredUsers(users);
    setActiveFilter('');
    setSortOption('');
  };

  const deleteUser = async (auth0Id, email) => {
    createModal(
      'Confirm Delete',
      <p style={{ textAlign: 'left' }}>
        Are you sure you want to delete this user{' '}
        <span className='text-danger'>{email}</span>?
      </p>,
      <>
        <Button
          color='secondary'
          onClick={async () => {
            setOverlay(false);
          }}
        >
          Cancel
        </Button>
        <Button
          color='danger'
          onClick={async () => {
            try {
              const token = await getAccessToken();
              if (token) {
                const config = {
                  headers: { Authorization: `Bearer ${token}` },
                };
                await api.delete(
                  `/users/${encodeURIComponent(auth0Id)}`,
                  config
                );
                setUsers(users.filter((user) => user.auth0_id !== auth0Id));
                setFilteredUsers(
                  filteredUsers.filter((user) => user.auth0_id !== auth0Id)
                );
              }
              setOverlay(false);
            } catch (error) {
              if (error.response && error.response.status === 404) {
                console.error('User not found. Error:', error);
              } else {
                console.error('Error deleting user:', error);
              }
            }
          }}
        >
          Confirm Delete
        </Button>
      </>
    );
    setOverlay('modal');
  };

  return ctxUser && ctxUser.plan && ctxUser.plan === 'admin' ? (
    <div className='container my-5'>
      <div className='card shadow rounded-5'>
        <div className='card-body'>
          <div className='card-title fs-5 fw-bold'>Basic stats</div>
          <ul className='list-group list-group-flush'>
            <li
              className={`list-group-item ${activeFilter ? '' : 'bg-light'}`}
              onClick={() => clearFilter()}
              style={{ cursor: 'pointer' }}
            >
              <div className='d-flex justify-content-between'>
                <span className='fs-6'>
                  <strong>Total Users:</strong>
                </span>
                <span className='fs-6'>
                  <strong>{users.length}</strong>
                </span>
              </div>
            </li>
            {['admin', 'friend', 'free', 'paid'].map((plan) => (
              <li
                key={plan}
                className={`list-group-item ${
                  activeFilter === plan ? 'bg-light' : ''
                }`}
                style={{ cursor: 'pointer' }}
                onClick={() => applyFilter(plan)}
              >
                <div className='d-flex justify-content-between'>
                  <span className='fs-6'>
                    <strong>{`${
                      plan.charAt(0).toUpperCase() + plan.slice(1)
                    } Users:`}</strong>
                  </span>
                  <span
                    className={`fs-6 ${plan === 'paid' ? 'text-primary' : ''}`}
                  >
                    <strong>
                      {users.filter((user) => user.plan === plan).length}
                    </strong>
                  </span>
                </div>
              </li>
            ))}
            <li className='list-group-item d-flex justify-content-between'>
              <div></div>
              <select
                className='form-select'
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                style={{ maxWidth: '200px', cursor: 'pointer' }}
              >
                <option value='userIdDesc'>Sign Up Date</option>
                <option value='lastActivityDesc'>Last Activity Date</option>
                <option value='taskCountDesc'>Task Count</option>
              </select>
            </li>
          </ul>
        </div>
      </div>
      <div className='my-3'></div>
      <div className='card shadow rounded-5'>
        <div className='card-body table-responsive'>
          <div className='card-title fs-5 fw-bold'>User Details</div>
          <table className='table'>
            <thead>
              <tr>
                <th className='not'></th>
              </tr>
            </thead>

            <tbody className='table-hover-custom'>
              {filteredUsers ? (
                filteredUsers.map((user) => (
                  <div className='card mb-3 fs-6' key={user.userId}>
                    <div className='card-header d-flex justify-content-between align-items-center bg-white fs-5'>
                      <span className='text-left font-weight-bold text-primary fs-6'>
                        <strong>{user.email}</strong>
                      </span>
                      {user &&
                      user.auth0_id &&
                      ctxUser &&
                      ctxUser.sub &&
                      user.auth0_id !== ctxUser.sub &&
                      user.plan === 'free' ? (
                        <Button
                          outline
                          color={'danger'}
                          className={`btn-light-danger-not-hover fs-6 ml-auto`}
                          onClick={() => {
                            deleteUser(user.auth0_id, user.email);
                          }}
                        >
                          <i className='fa-solid fa-circle-xmark'></i>
                        </Button>
                      ) : null}
                    </div>
                    <ul className='list-group list-group-flush fs-6'>
                      <li className='list-group-item d-flex justify-content-between fs-6'>
                        <span>
                          <strong>Plan:</strong>
                        </span>
                        <span>
                          {user.plan === 'paid' ? (
                            <strong className='text-primary'>paid</strong>
                          ) : (
                            <strong>{user.plan}</strong>
                          )}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between fs-6'>
                        <span>
                          <strong>Task Count:</strong>
                        </span>{' '}
                        <span>
                          <strong>{user.task_count}</strong>
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between fs-6'>
                        <span>
                          <strong>Sign-up Date:</strong>
                        </span>{' '}
                        <span>
                          <strong>
                            {new Date(user.created_at).toLocaleDateString(
                              'en-US',
                              {
                                timeZone: 'EST',
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                              }
                            )}
                          </strong>
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between fs-6'>
                        <span>
                          <strong>Last Activity Date:</strong>
                        </span>{' '}
                        <span>
                          <strong>
                            {new Date(
                              user.last_activity_timestamp
                            ).toLocaleDateString('en-US', {
                              timeZone: 'EST',
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            })}
                          </strong>
                        </span>
                      </li>
                    </ul>
                  </div>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className='fs-5'>
                    Error
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <>
      <img
        src={spinner}
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
        }}
        width='400'
        height='400'
      />
      <Navigate to='/' />
    </>
  );
};

export default Admin;
