import { useContext } from 'react';
import Button from '../components/shared/Button';
import { AllowanceContext } from '../context/AllowanceContext';
import WhatAMesh from '../components/shared/WhatAMesh';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import image from '../assets/Allowance Track - Best Chore Tracking App High Quality.jpg';
import Hero from '../components/Hero';
// import whatAMesh from '../whatAMesh.js';
import { motion } from 'framer-motion';

const Landing = () => {
  const { loginWithRedirect } = useAuth0();
  const { createModal, baseUrl } = useContext(AllowanceContext);
  return (
    <motion.div initial={{ opacity: '0' }} animate={{ opacity: '1' }}>
      <Helmet>
        <title>Allowance Track | The #1 Allowance Tracker</title>
        <meta
          name='title'
          content='Allowance Track | The #1 Allowance Tracker'
        ></meta>
        <meta
          name='description'
          content="Discover the ultimate allowance tracker! Allowance Track empowers parents to effortlessly oversee and manage their kids' allowances with a user-friendly platform. Engage in setting tasks, tracking progress, and rewarding achievements. Start simplifying your financial parenting journey today!"
        ></meta>
        <meta
          name='keywords'
          content='allowance tracker app, allowance tracker, allowance tracking, children allowance management, family finance tool, kids money management, task rewards system, financial literacy for kids, parental control finance, digital allowance system, child allowance app, manage kids money, financial planning for kids, allowance tracking software'
        ></meta>
        <meta
          property='og:title'
          content='Allowance Track | The #1 Allowance Tracker'
        />
        <meta
          property='og:description'
          content="Discover the ultimate allowance tracker! Allowance Track empowers parents to effortlessly oversee and manage their kids' allowances with a user-friendly platform. Engage in setting tasks, tracking progress, and rewarding achievements. Start simplifying your financial parenting journey today!"
        ></meta>
        <meta property='og:type' content='website' />
        <meta property='og:url' content={`${process.env.REACT_APP_BASE_URL}`} />
        <meta property='og:image' content={image}></meta>
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:title'
          content='Allowance Track | The #1 Allowance Tracker'
        />
        <meta
          name='twitter:description'
          content="Discover the ultimate allowance tracker! Allowance Track empowers parents to effortlessly oversee and manage their kids' allowances with a user-friendly platform. Engage in setting tasks, tracking progress, and rewarding achievements. Start simplifying your financial parenting journey today!"
        />
        <meta name='twitter:image' content={image}></meta>
      </Helmet>
      <main>
        <Hero />
        <div className='container gap-3 my-5 mb-0' id='landing-content'>
          <h1 className='text-center mb-4'>
            <span className='animated-text animation-delay-lg'>Sync</span> and{' '}
            <span className='animated-text animation-delay-md'>Organize</span>{' '}
            Your <span className='animated-text'>Chores</span> Effortlessly
          </h1>

          <div className='row m-0'>
            <div className='col-lg-3'>
              <div className='card shadow text-center mb-3'>
                <div>
                  <i className='fas fa-list-alt fa-3x mt-4'></i>
                  <div className='card-body'>
                    <h5 className='card-title'>Ultimate Chore Chart</h5>
                    <p className='card-text'>
                      Easily create chores and set flexible schedules. Track and
                      reward progress with ease with our digital chore chart.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-3'>
              <div className='card shadow text-center mb-3'>
                <div>
                  <i className='fas fa-sync fa-3x mt-4'></i>
                  <div className='card-body'>
                    <h5 className='card-title'>Real-Time Progress</h5>
                    <p className='card-text'>
                      Instantly view your child's achievements and allowance
                      earnings throughout the week with our real-time chore
                      board.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-3'>
              <div className='card shadow text-center mb-3'>
                <div>
                  <i className='fas fa-piggy-bank fa-3x mt-4'></i>
                  <div className='card-body'>
                    <h5 className='card-title'>Financial Responsibility</h5>
                    <p className='card-text'>
                      Teach your child the value of money and hard work by
                      associating effort with allowance earnings.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-3'>
              <div className='card shadow text-center mb-3'>
                <div>
                  <i className='fas fa-handshake fa-3x mt-4'></i>
                  <div className='card-body'>
                    <h5 className='card-title'>Collaboration</h5>
                    <p className='card-text'>
                      Strengthen parent-child communication by reviewing chores
                      with our allowance tracking chore chart.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='text-center mt-5'>
              <Button
                color='primary'
                className='bg-animated-b-g'
                data-bs-toggle='modal'
                data-bs-target='#modal'
                onClick={() => {
                  createModal(
                    'Choose a plan!',
                    'PRICING',
                    <>
                      <Button
                        outline
                        color='primary'
                        onClick={() => {
                          loginWithRedirect({
                            authorizationParams: {
                              screen_hint: 'signup',
                              redirect_uri: `${baseUrl}`,
                            },
                          });
                        }}
                      >
                        Free Plan
                      </Button>
                      <Button
                        color='success'
                        className='bg-animated-b-g'
                        onClick={() => {
                          loginWithRedirect({
                            authorizationParams: {
                              screen_hint: 'signup',
                              redirect_uri: `${baseUrl}/billing`,
                            },
                          });
                        }}
                      >
                        Plus Plan
                      </Button>
                    </>,
                    false
                  );
                }}
              >
                Get Started Now
              </Button>
            </div>
          </div>
          <div
            className='container gap-3 my-5'
            style={{ paddingTop: '2rem', paddingBottom: '1rem' }}
          >
            <h1 className='text-center mb-4'>
              Do more with{' '}
              <span className='animated-text position-relative'>
                {' '}
                <span>Plus</span>
                <span
                  className='position-absolute start-150 translate-middle badge rounded-pill border border-primary text-primary'
                  style={{ fontSize: '0.6rem' }}
                >
                  $5/mo
                </span>
              </span>
            </h1>

            <div className='row m-0'>
              <div className='col-lg-3'>
                <div className='card shadow text-center mb-3'>
                  <div>
                    <i className='fas animated-text fa-infinity fa-3x mt-4'></i>
                    <div className='card-body'>
                      <h5 className='card-title animated-text'>
                        Unlimited Chores
                      </h5>
                      <p className='card-text'>
                        Never be restricted again. Add as many chores as you
                        need!
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-3 animation-delay-sm'>
                <div className='card shadow text-center mb-3 position-relative'>
                  {/* <i className='fas animated-text fa-users fa-3x mt-4'></i> */}
                  <div>
                    <i className='fas animated-text fa-children fa-3x mt-4'></i>
                    <div className='card-body'>
                      <h5 className='card-title animated-text'>
                        Multiple Kids
                      </h5>
                      <p className='card-text'>
                        Track allowances and chores for each of your kids - all
                        in one account
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-3 animation-delay-md'>
                <div className='card shadow text-center mb-3'>
                  <div>
                    <i className='fas fa-user-check fa-3x mt-4 animated-text'></i>
                    <div className='card-body'>
                      <h5 className='card-title animated-text'>Ad-Free</h5>
                      <p className='card-text'>
                        Eliminate ads. Let chores and rewards take center stage,
                        distraction-free.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-3 animation-delay-lg'>
                <div className='card shadow text-center mb-3'>
                  <div>
                    <i className='fas animated-text fa-calendar-week fa-3x mt-4'></i>
                    <div className='card-body'>
                      <h5 className='card-title animated-text'>
                        Multiple Weeks
                      </h5>
                      <p className='card-text'>
                        Keep track of chores across multiple weeks and view them
                        anytime.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='text-center mt-5'>
              <Button
                color='primary'
                className='bg-animated-b-g'
                onClick={() => {
                  loginWithRedirect({
                    authorizationParams: {
                      screen_hint: 'signup',
                      redirect_uri: `${baseUrl}/billing`,
                    },
                  });
                }}
              >
                Plus, please!
              </Button>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* <div className='mb-5' style={{ marginTop: '-3rem' }}>
          <h1
            className='display-3 fw-thin mx-3'
            id='tagline'
            style={{ textAlign: 'left' }}
          >
            Try the{' '}
            <span className='animated-text'>
              <span>#1 Allowance Tracker</span>
            </span>{' '}
            today
          </h1>
          <h3
            className='fw-normal text-muted mb-3 fs-5 font-md-responsive d-block mx-3'
            style={{ textAlign: 'left' }}
            id='tag-desc'
          >
            Imagine a world where managing your child's allowance is not just
            easy, but actually enjoyable. With our allowance tracker, you can
            transform the way you handle finances, making every dollar count
            towards building responsible financial habits. Say goodbye to the
            hassle of tracking allowances manually and hello to a seamless,
            interactive experience that both you and your child will love. Don't
            miss out on the opportunity to make allowance management a breeze.
            Sign up now and take the first step towards financial savvy
            parenting!
          </h3>
          <div className='d-flex gap-3 justify-content-center lead fw-normal'>
            <Button
              color='primary'
              className='bg-animated-b-g'
              onClick={() => {
                loginWithRedirect({
                  authorizationParams: { screen_hint: 'signup' },
                });
              }}
            >
              Sign Up
            </Button>
          </div>
        </div> */}
        <div className='container w-sm-100 mb-4 mb-sm-5 mt-0' id='hero'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                width: '100%',
                margin: 0,
              }}
            >
              <div>
                <h1 className='display-3 fw-thin' id='tagline'>
                  Try the{' '}
                  <span className='animated-text'>
                    <span>#1 Digital Chore Chart & Allowance Tracker</span>
                  </span>{' '}
                  today
                </h1>
                <h3
                  className='fw-normal text-muted mb-3 fs-5 w-75 m-auto font-md-responsive'
                  style={{ width: '50%' }}
                  id='tag-desc'
                >
                  <span className='d-block mt-4'>
                    Imagine a world where managing your child's allowance is not
                    just easy, but actually enjoyable. With our allowance
                    tracking app, you can transform the way you handle your
                    child's allowance earnings, making every dollar count
                    towards building responsible financial habits. Now,
                    seamlessly integrate the power of a chore app into this
                    experience. Our innovative chore tracker and chore checklist
                    functionalities are built into our allowance tracker, making
                    it the ultimate app for tracking chores and allowance
                    earnings together.
                  </span>
                  <span className='d-block mt-4 mb-1'>
                    Say goodbye to the hassle of tracking allowances and chores
                    manually and hello to a seamless, interactive experience
                    that both you and your child will love. Our platform is
                    designed to make allowance management and chore tracking a
                    breeze. Our allowance app provides an engaging and
                    motivational experience. Sign up now and take the first step
                    towards financial savvy parenting with the leading chore app
                    trusted by parents worldwide!
                  </span>
                </h3>
                <div className='d-flex gap-4 justify-content-center lead fw-normal mt-1'>
                  <Button
                    color='primary'
                    className='bg-animated-b-g mt-1'
                    data-bs-toggle='modal'
                    data-bs-target='#modal'
                    onClick={() => {
                      createModal(
                        'Choose a plan!',
                        'PRICING',
                        <>
                          <Button
                            outline
                            color='primary'
                            onClick={() => {
                              loginWithRedirect({
                                authorizationParams: {
                                  screen_hint: 'signup',
                                  redirect_uri: `${baseUrl}`,
                                },
                              });
                            }}
                          >
                            Free Plan
                          </Button>
                          <Button
                            color='success'
                            className='bg-animated-b-g'
                            onClick={() => {
                              loginWithRedirect({
                                authorizationParams: {
                                  screen_hint: 'signup',
                                  redirect_uri: `${baseUrl}/billing`,
                                },
                              });
                            }}
                          >
                            Plus Plan
                          </Button>
                        </>,
                        false
                      );
                    }}
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className='p-5'>
        <div className='row g-0 rounded overflow-hidden flex-md-row mb-0 shadow-sm border border-light h-md-250 position-relative'>
          <div className='col-auto d-block vert-cont w-25'>
            <img
              src='https://acronotes.com/static/media/logo.965c2701732cfb4c8f15.png'
              alt='Acronotes'
              className='vert-target'
              width='100'
            />
          </div>
          <div className='col p-4 d-flex flex-column position-static'>
            <strong className='d-inline-block mb-2 text-primary-emphasis'>
              Our{' '}
              <b>
                <b>External</b>
              </b>{' '}
              Free Tool:
            </strong>
            <h3 className='mb-0 animated-text'>Acronotes</h3>
            <div className='mb-1 text-body-secondary'>
              Say Goodbye to messy notes, say hello to Acronotes
            </div>
            <p className='card-text mb-auto'>
              Acronotes is the ultimate way to memorize your notes. You can
              convert them to an easy to remember acronym to remember them
              anytime, anywhere. Powered by{' '}
              <span className='animated-text'>world-class AI.</span>
            </p>
            <a
              href='https://acronotes.com'
              about='blank'
              target='_blank'
              className='icon-link icon-link-hover gap-1 stretched-link mx-auto focus-ring rounded p-1'
            >
              Learn More
              <i
                className='fas fa-chevron-right bi'
                style={{ fontSize: '0.75rem', marginBottom: '-5px' }}
              ></i>
            </a>
          </div>
        </div>
      </div>

      <footer className='container' style={{ paddingBottom: '0' }}>
        <div className='row'>
          <div className='col-12 col-md mb-5'>
            <a href='/blog' className='text-primary d-block mb-3 fs-5 mb-5'>
              Visit our Blog
            </a>
            <small className='d-block mb-3 text-body-secondary'>
              © 2024 Allowance Track All Rights Reserved
            </small>
          </div>
        </div>
      </footer>
    </motion.div>
  );
};
export default Landing;
