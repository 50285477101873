import { useContext, useEffect, useState } from "react";
import WaveText from "../components/WaveText";
import Button from "../components/shared/Button";
import { AllowanceContext } from "../context/AllowanceContext";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import envelope from "../assets/envelope.gif";

const Verify = () => {
  const {
    getVerificationCode,
    billing,
    verifyEmail,
    loading,
    user: ctxUser,
    code,
  } = useContext(AllowanceContext);
  const { isLoading, user } = useAuth0();
  const navigate = useNavigate();
  // const location = useLocation();
  const [enteredCode, setEnteredCode] = useState("");
  const [prevCtxUser, setPrevCtxUser] = useState();
  const [running, setRunning] = useState(false);
  const [hasRequestedCode, setHasRequestedCode] = useState(false); // New state variable

  // useEffect for requesting verification code
  useEffect(() => {
    const requestVerificationCode = async () => {
      if (
        !hasRequestedCode &&
        user &&
        !loading &&
        !isLoading &&
        !code &&
        !running
      ) {
        setRunning(true);
        setHasRequestedCode(true);
        await getVerificationCode(ctxUser, loading, code, running);
        setRunning(false);
      }
    };

    requestVerificationCode();
    return;
  }, [code, isLoading, user]);

  if (!process.env.REACT_APP_EMAIL_VERIFICATION) {
    return null;
  }

  return loading ? (
    <div></div>
  ) : (
    <div
      // className='modal-backdrop'
      // className='px-3 vh-100 position-absolute justify-content-center align-items-center d-flex modal-backdrop'
      style={{
        zIndex: "214748364",
      }}
    >
      <div
        className="modal-backdrop show"
        style={{
          zIndex: "1054",
        }}
      ></div>
      <div className="modal show" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content rounded-5 shadow-lg border-0">
            {/* <div className='modal-header'>
              <div className='d-block mx-0 w-50'>
                <img src={envelope} width={100} height={60} />
              </div>
              <h1 className='modal-title w-100' style={{ marginRight: 'auto' }}>
                Verify your Email
              </h1>
              <div className='w-50'></div> */}
            {/* <div style={{ width: '100px', height: '60px' }}></div> */}
            {/* </div> */}
            <div className="modal-header d-block">
              <div className="d-block mx-0 w-100">
                <img
                  src={envelope}
                  width={100}
                  height={60}
                  style={{ marginRight: "25px" }}
                />
              </div>
              <h1 className="modal-title w-100" style={{ marginRight: "auto" }}>
                Verify your Email
              </h1>
              <div className="w-50"></div>
              {/* <div style={{ width: '100px', height: '60px' }}></div> */}
            </div>
            <div className="modal-body">
              <p className="lead">
                Please verify your email to use our application. If you didn't
                receive an email, click the Resend button below.
              </p>
              <input
                type="number"
                className="form-control form-control-lg w-75 mx-auto"
                value={enteredCode}
                onChange={async (e) => {
                  const emailVerified =
                    e.target.value.length == 4 && verifyEmail(e.target.value);
                  setEnteredCode(emailVerified ? "" : e.target.value);
                }}
                placeholder="Code"
              />
              <p
                className="opacity-50 my-3 mx-3 mb-0 mx-auto"
                style={{ fontSize: "10px" }}
              >
                * By entering a verification code above you agree that you have
                read and agree to the{" "}
                <Link
                  to="/legal/terms?show=false"
                  target="_blank"
                  rel="noreferrer"
                >
                  Allowance Track Terms of Use
                </Link>
              </p>
            </div>
            <div className="modal-footer">
              <div className="mx-auto">
                <Button
                  onClick={async () => {
                    await getVerificationCode();
                  }}
                  className="btn btn-lg btn-secondary border-secondary bg-secondary"
                >
                  Resend
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className='card shadow-lg rounded-5 modal modal-dialog-centered'
        tabIndex={-1}
        style={{ margin: '10dvw', padding: '5dvh 2.5dvw' }}
      >
        <div className='modal-content'>
          <h1>Verify your Email</h1>
          <p className='lead'>
            We require you to verify your email to use our application. Once you
            have verified, click the I Verified button below and the page will
            update. If you didn't receive an email, click the Resend button
            below.
          </p>
          <p className='lead'>
            <Button
              onClick={async () => {
                loginWithRedirect();
              }}
              className='btn btn-lg btn-primary border-primary bg-primary'
            >
              I Verified
            </Button>
            <span className='mx-2'></span>
            <Button
              onClick={async () => {
                await reVerify();
              }}
              className='btn btn-lg btn-secondary border-secondary bg-secondary'
            >
              Resend
            </Button>
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default Verify;
