import { useContext, useEffect } from 'react';
import spinner from '../assets/wired-gradient-105-loader-1 (1)-min.gif';
import { AllowanceContext } from '../context/AllowanceContext';
import { Helmet } from 'react-helmet';

const Billing = () => {
  const { user, checkout, portal, setBilling, removeBilling } =
    useContext(AllowanceContext);

  useEffect(() => {
    if (
      user &&
      user.plan == 'free' &&
      (!process.env.REACT_APP_EMAIL_VERIFICATION || user.email_verified)
    ) {
      checkout(true);
      removeBilling();
    } else if (
      user &&
      user.plan != 'free' &&
      (!process.env.REACT_APP_EMAIL_VERIFICATION || user.email_verified)
    ) {
      portal();
      removeBilling();
    } else {
      setBilling(true);
    }
  }, [user]);

  return (
    <>
      <Helmet>
        <title>Loading... | Allowance Track</title>
      </Helmet>
      <img
        src={spinner}
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
        }}
        width='400'
        height='400'
      />
    </>
  );
};
export default Billing;
