import {
  forwardRef,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { AllowanceContext } from '../context/AllowanceContext';
import Button from './shared/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { motion, AnimatePresence } from 'framer-motion';

const TaskRow = forwardRef(({ task }, ref) => {
  const {
    updateChecked,
    updateTask,
    removeTask,
    tokenExpired,
    loading,
    // createModal,
    // hideModal,
  } = useContext(AllowanceContext);

  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const [edit, setEdit] = useState(false);
  const [newName, setNewName] = useState(task.task);

  const [reqDays, setReqDays] = useState(
    task.checkStates ? task.checkStates.map((s) => s !== null) : null
  );

  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  const cRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const cRefsMobile = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const inpRef = useRef();

  useEffect(() => {
    cRefs.forEach((ref, i) => {
      if (ref.current) {
        // Ensure the ref is attached to an element
        ref.current.indeterminate = task.checkStates[i] == null;
      }
    });
  }, [cRefs, cRefsMobile, task.checkStates]);
  useEffect(() => {
    cRefsMobile.forEach((ref, i) => {
      if (ref.current) {
        // Ensure the ref is attached to an element
        ref.current.indeterminate = task.checkStates[i] == null;
      }
    });
  }, [cRefs, cRefsMobile, task.checkStates]);

  if (task.loading) {
    return (
      <>
        <motion.tr
          initial={{ opacity: '0' }}
          animate={{ opacity: '1' }}
          exit={{ opacity: '0' }}
          key={`mobile1_${
            typeof task.id == 'symbol' ? task.id.toString() : task.id
          }`}
          className='placeholder-glow bg-secondary-subtle not-mobile'
          style={{
            minHeight: '1em',
            verticalAlign: 'middle',
            cursor: 'wait',
            backgroundColor: '#f8f9fc',
          }}
        >
          <td
            colSpan={10}
            className='p-0'
            ref={(node) => {
              if (node) {
                node.style.setProperty('height', '0px', 'important');
                node.style.setProperty('max-height', '0px', 'important');
              }
            }}
            style={{
              height: `31px ${`!${`important`}`}`,
              minHeight: '31px',
              maxHeight: '31px',
            }}
          >
            <div
              className={`p-0 d-flex placeholder rounded`}
              style={{
                // margin: '5px',
                width: '100%',
                height: '31px',
              }}
            ></div>
          </td>
        </motion.tr>
        <motion.tr
          initial={{ opacity: '0' }}
          animate={{ opacity: '1' }}
          exit={{ opacity: '0' }}
          ref={ref}
          key={`mobile2_${
            typeof task.id == 'symbol' ? task.id.toString() : task.id
          }`}
          className='placeholder-glow bg-secondary-subtle not-desktop'
          style={{
            minHeight: '1em',
            verticalAlign: 'middle',
            cursor: 'wait',
            backgroundColor: '#f8f9fc',
          }}
        >
          <td
            colSpan={10}
            className='p-0'
            ref={(node) => {
              if (node) {
                node.style.setProperty('height', '0px', 'important');
                node.style.setProperty('max-height', '0px', 'important');
              }
            }}
            style={{
              height: `31px ${`!${`important`}`}`,
              minHeight: '31px',
              maxHeight: '31px',
            }}
          >
            <div className='d-flex justify-content-between p-2 mobile-task-header'>
              <div
                className='d-flex align-items-center justify-content-between'
                style={{ flexGrow: '1', textAlign: 'center' }}
              >
                <span
                  className={`p-0 d-flex placeholder rounded d-inline`}
                  style={{
                    // margin: '5px',
                    width: '29px',
                    height: '31px',
                  }}
                ></span>
                <span
                  className={`mx-2 placeholder rounded`}
                  style={{
                    // margin: '5px',
                    height: '31px',
                  }}
                >
                  {task.task}
                </span>
                <span
                  className={`p-0 d-flex placeholder rounded d-inline`}
                  style={{
                    // margin: '5px',
                    width: '29px',
                    height: '31px',
                  }}
                ></span>
              </div>
            </div>
          </td>
        </motion.tr>
        <motion.tr
          initial={{ opacity: '0' }}
          animate={{ opacity: '1' }}
          exit={{ opacity: '0' }}
          key={`mobile_row_${
            typeof task.id == 'symbol' ? task.id.toString() : task.id
          }`}
          className='placeholder-glow bg-secondary-subtle not-desktop'
          style={{
            minHeight: '1em',
            verticalAlign: 'middle',
            cursor: 'wait',
            backgroundColor: '#f8f9fc',
          }}
        >
          <td
            colSpan={10}
            className='p-0'
            ref={(node) => {
              if (node) {
                node.style.setProperty('height', '0px', 'important');
                node.style.setProperty('max-height', '0px', 'important');
              }
            }}
            style={{
              height: `31px ${`!${`important`}`}`,
              minHeight: '31px',
              maxHeight: '31px',
            }}
          >
            <div
              className={`p-0 d-flex placeholder rounded`}
              style={{
                // margin: '5px',
                width: '100%',
                height: '31px',
              }}
            ></div>
          </td>
        </motion.tr>
      </>
      // </AnimatePresence>
    );
  }

  const collapseId = `collapse_${task.id}`;

  return (
    <>
      {/* {windowWidth >= 860 ? ( */}
      {/* <AnimatePresence> */}
      <motion.tr
        initial={{ opacity: '0' }}
        animate={{ opacity: '1' }}
        exit={{ opacity: '0' }}
        key={task.id}
        className='not-mobile'
      >
        <td
          className='p-0'
          style={{ margin: 'none', padding: 'none !important' }}
        >
          <div
            className={`${edit && 'input-group input-group-sm'} p-0 d-flex`}
            style={{
              margin: '5px',
              width: '100%',
            }}
          >
            <Button
              outline
              color={!edit ? 'info' : 'success'}
              disabled={tokenExpired}
              className={
                tokenExpired
                  ? 'btn-sm'
                  : !edit
                  ? 'btn-light-info-not-hover btn-sm'
                  : 'btn-light-success-not-hover'
              }
              style={{
                border: 'none',
              }}
              // data-bs-toggle={!isAuthenticated && !loading && 'modal'}
              // data-bs-target={!isAuthenticated && !loading && '#modal'}
              onClick={() => {
                if (!loading) {
                  if (edit) {
                    // if (
                    //   task.task !== newName &&
                    //   reqDays.length === task.checkStates.length &&
                    //   reqDays.every(
                    //     (element, index) =>
                    //       !element ||
                    //       (element === true &&
                    //         task.checkStates[index] === null)
                    //   )
                    // ) {
                    updateTask(reqDays, newName, task.id);
                    // }
                  } else if (inpRef.current) {
                    setTimeout(
                      () => inpRef.current.focus(),
                      '0.00000000000001'
                    );
                  }
                  setEdit(!edit ? true : false);
                }
                // else {
                //   createModal(
                //     'Sign up to use this feature!',
                //     <>
                //       <p className='my-0' style={{ textAlign: 'left' }}>
                //         You have to sign up to be able to edit tasks!
                //       </p>
                //     </>,
                //     'ACCOUNT_REQUIRED'
                //   );
                // }
              }}
            >
              {!edit ? (
                <i className='fa-solid fa-pen'></i>
              ) : (
                <i className='fa-solid fa-check'></i>
              )}
            </Button>
            <input
              type='text'
              className={`form-control form-control-sm text-center input-sm ${
                edit ? 'accent' : ''
              }`}
              disabled={!edit}
              style={{
                border: 'none',
                backgroundColor: !edit && 'transparent',
                cursor: !edit && 'text',
                minWidth: '200px',
              }}
              value={newName}
              onChange={(e) => {
                setNewName(e.target.value);
              }}
              ref={inpRef}
            />
          </div>
        </td>
        <td>|</td>
        {task.checkStates.map((value, index) => {
          if (value !== null) {
            return (
              <td key={index}>
                <input
                  type='checkbox'
                  className={`form-check-input check-1`}
                  ref={(current) => {
                    cRefs[index].current = current;
                  }}
                  checked={value}
                  onChange={(e) => {
                    const updated = [...task.checkStates];

                    updated[index] = e.target.checked;

                    updateChecked(updated, task.id);
                  }}
                />
              </td>
            );
          } else {
            return (
              <td key={index}>
                <input
                  type='checkbox'
                  className='form-check-input check-1 accent'
                  checked={value}
                  ref={(current) => {
                    cRefs[index].current = current;
                  }}
                  disabled={true}
                />
              </td>
            );
          }
        })}
        <td>
          <i
            className='fa-regular fa-circle-xmark link-secondary hover-danger'
            onClick={() => removeTask(task.id)}
          ></i>
        </td>
      </motion.tr>
      <motion.tr
        className={`collapse ${edit ? 'show' : 'hide'} not-mobile`}
        id={collapseId}
      >
        <td>
          <p
            style={{
              marginLeft: '31px',
              marginBottom: '0',
              color: 'var(--bs-primary)',
              fontSize: '.875rem',
            }}
          >
            Days required:
          </p>
        </td>
        <td>|</td>
        {reqDays.map((day, i) => {
          return (
            <td
              key={i}
              style={{
                paddingLeft: '0',
                paddingRight: '0',
              }}
            >
              <div
                className='form-check form-switch'
                style={{
                  padding: '0',
                  margin: 'auto',
                  width: 'fit-content',
                }}
              >
                <input
                  type='checkbox'
                  checked={day}
                  onChange={(e) => {
                    let newReq = [...reqDays];
                    newReq[i] = e.target.checked;
                    setReqDays(newReq);
                  }}
                  className='form-check-input'
                  style={{
                    marginLeft: '0px',
                  }}
                />
              </div>
            </td>
          );
        })}
        <td
          style={{
            padding: '5.2px',
          }}
        >
          <Button
            outline
            color={'success'}
            className={'btn-light-success-not-hover btn-sm hover-white'}
            style={{
              border: 'none',
              margin: 'auto',
              color: 'black',
            }}
            onClick={() => {
              updateTask(reqDays, newName, task.id);
              setEdit(!edit);
            }}
          >
            Save
          </Button>
        </td>
      </motion.tr>
      {/* </AnimatePresence> */}
      {/* ) : ( */}
      {/* ------------------------------- */}
      {/* ------------------------------- */}
      {/* ------------------------------- */}
      {/* ------------------------------- */}
      {/* ------------------------------- */}
      {/* ------------------------------- */}
      {/* EVERYTHING BELOW HERE IS MOBILE */}
      {/* ------------------------------- */}
      {/* ------------------------------- */}
      {/* ------------------------------- */}
      {/* ------------------------------- */}
      {/* ------------------------------- */}
      {/* ------------------------------- */}
      {/* <AnimatePresence> */}
      <motion.tr
        initial={{ opacity: '0' }}
        animate={{ opacity: '1' }}
        exit={{ opacity: '0' }}
        key={`checkbox_row_${task.id}`}
        className='border-white'
        id='not-desktop'
      >
        <td className='p-0' colSpan={9}>
          <div className='d-flex justify-content-between p-2 mobile-task-header'>
            <div
              className='d-flex align-items-center justify-content-between'
              style={{
                flexGrow: 1,
                textAlign: 'center',
              }}
            >
              <Button
                outline
                color={!edit ? 'info' : 'success'}
                disabled={tokenExpired}
                className={`${
                  !edit
                    ? 'btn-light-info-not-hover'
                    : 'btn-light-success-not-hover'
                } btn-sm`}
                // data-bs-toggle={
                //   !loading ? 'modal' : undefined
                // }
                // data-bs-target={
                //   !loading ? '#modal' : undefined
                // }
                onClick={() => {
                  if (!loading) {
                    if (edit) {
                      // if (
                      //   task.task !== newName &&
                      //   reqDays.length === task.checkStates.length &&
                      //   reqDays.every(
                      //     (element, index) =>
                      //       !element ||
                      //       (element === true &&
                      //         task.checkStates[index] === null)
                      //   )
                      // ) {
                      updateTask(reqDays, newName, task.id);
                      // }
                    } else {
                      inpRef.current &&
                        setTimeout(() => inpRef.current.focus(), 1);
                    }
                    setEdit(!edit);
                  }
                  // else {
                  //   createModal(
                  //     'Sign up to use this feature!',
                  //     <p className='my-0' style={{ textAlign: 'left' }}>
                  //       You have to sign up to be able to edit tasks!
                  //     </p>,
                  //     'ACCOUNT_REQUIRED'
                  //   );
                  // }
                }}
                style={{ border: 'none' }}
              >
                {!edit ? (
                  <i className='fa-solid fa-pen'></i>
                ) : (
                  <i className='fa-solid fa-check'></i>
                )}
              </Button>
              <div className='w-100 mx-auto'>
                <input
                  type='text'
                  className={`form-control form-control-sm text-center input-sm ${
                    edit ? 'accent' : ''
                  } w-100 mx-auto`}
                  disabled={!edit}
                  style={{
                    border: 'none',
                    backgroundColor: !edit ? 'transparent' : undefined,
                    cursor: !edit ? 'text' : undefined,
                    minWidth: '100%',
                  }}
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  ref={inpRef}
                />
              </div>
              <Button
                outline
                color={'danger'}
                className={`btn-sm btn-light-danger-not-hover`}
                onClick={() => {
                  removeTask(task.id);
                }}
                style={{ border: 'none' }}
              >
                <i className='fa-solid fa-circle-xmark'></i>
              </Button>
            </div>
          </div>
        </td>
      </motion.tr>
      <motion.tr
        initial={{ opacity: '0' }}
        animate={{ opacity: '1' }}
        exit={{ opacity: '0' }}
        className='mobile-checkbox-row'
        key={`checkbox-row-${task.id}`}
        style={{
          display: !edit ? 'none' : 'table-row',
        }}
        id='not-desktop'
      >
        <td colSpan={9} className='pt-0'>
          <div
            className='d-flex'
            style={{
              flexGrow: '1',
              justifyContent: 'space-between',
              marginTop: '0.5rem',
            }}
          >
            <span className='mx-3'>Days Required:</span>
            <Button
              outline
              color={'success'}
              className={'btn-light-success-not-hover btn-sm hover-white'}
              style={{
                border: 'none',
                color: 'black',
              }}
              onClick={() => {
                updateTask(reqDays, newName, task.id);
                setEdit(!edit);
              }}
            >
              Save
            </Button>
          </div>
        </td>
      </motion.tr>
      <motion.tr
        initial={{ opacity: '0' }}
        animate={{ opacity: '1' }}
        exit={{ opacity: '0' }}
        className='mobile-checkbox-row'
        style={{ display: edit && 'none' }}
        key={`checkbox-row-${task.id}-2`}
        id='not-desktop'
      >
        <td colSpan={9} className='pt-0'>
          <div
            className='d-flex justify-content-between p-2 pt-1'
            style={{ justifyContent: 'space-between' }}
          >
            {task.checkStates.map((value, index) => {
              const dayLetter = 'MTWTFSS'[index]; // Days abbreviations
              return (
                <div
                  key={index}
                  className='mobile-checkbox-container text-center'
                  style={{ height: '50px', width: '20px' }}
                >
                  <label className='mobile-day-letter d-block'>
                    {dayLetter}
                  </label>
                  {value !== null ? (
                    <div key={index}>
                      <input
                        type='checkbox'
                        className={`form-check-input check-1`}
                        ref={(current) => {
                          cRefsMobile[index].current = current;
                        }}
                        checked={value}
                        onChange={(e) => {
                          const updated = [...task.checkStates];
                          updated[index] = e.target.checked;
                          updateChecked(updated, task.id);
                        }}
                      />
                    </div>
                  ) : (
                    <div key={index}>
                      <input
                        type='checkbox'
                        className='form-check-input check-1 accent'
                        checked={value}
                        ref={(current) => {
                          cRefsMobile[index].current = current;
                        }}
                        disabled={true}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </td>
      </motion.tr>
      <motion.tr
        className={`collapse ${edit ? 'show' : ''}  not-desktop`}
        id={collapseId}
      >
        {/* <td>
          <p
            style={{
              marginLeft: '31px',
              marginBottom: '0',
              color: 'var(--bs-primary)',
              fontSize: '.875rem',
            }}
          >
            Days required:
          </p>
        </td>
        <td>|</td> */}
        {reqDays.map((day, i) => {
          const dayLetter = 'MTWTFSS'[i];
          return (
            <td
              key={i}
              style={{
                paddingLeft: '0',
                paddingRight: '0',
              }}
            >
              <label className='mobile-day-letter d-block'>{dayLetter}</label>
              <div
                className='form-check form-switch'
                style={{
                  padding: '0',
                  margin: 'auto',
                  width: 'fit-content',
                }}
              >
                <input
                  type='checkbox'
                  checked={day}
                  onChange={(e) => {
                    let newReq = [...reqDays];
                    newReq[i] = e.target.checked;
                    setReqDays(newReq);
                  }}
                  className='form-check-input'
                  style={{
                    marginLeft: '0px',
                  }}
                />
              </div>
            </td>
          );
        })}
      </motion.tr>
      {/* </AnimatePresence> */}
      {/* )} */}
    </>
  );
});

export default TaskRow;
