import { useContext, useState } from 'react';
import Menu from './Menu';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { AllowanceContext } from '../../context/AllowanceContext';
import Button from '../shared/Button';
import img from '../../assets/allowancetrack.png';
import plus from '../../assets/allowancetrackplus.png';
import { Auth0Context } from '@auth0/auth0-react';

const Navbar = () => {
  const { percentCompleted, loading, user, reVerify } =
    useContext(AllowanceContext);
  const { isAuthenticated } = useContext(Auth0Context);

  const [showVer, setShowVer] = useState(true);

  return (
    <>
      {/* Top Bar
        This is the bar at the top of the page with the title & progress bar
      */}
      <nav className='navbar sticky-top bg-light' style={{ zIndex: '1054' }}>
        <div className='container-fluid align-items-center' id='top-bar'>
          <Link to='/' className='link-dark' style={{ textDecoration: 'none' }}>
            <h1
              className='display-5 p-2'
              style={{ marginBottom: '0', textDecoration: 'none' }}
            >
              <img
                src={user && user.plan == 'paid' ? plus : img}
                alt='A'
                width={41}
                height={41}
                style={{
                  marginBottom: 'calc((1.425rem + 2.1vw) / 4)',
                  marginRight: '10px',
                  width: 'calc(1.425rem + 2.1vw)',
                  height: 'calc(1.425rem + 2.1vw)',
                }}
              />
              <span className='at-title'>Allowance Track</span>
            </h1>
          </Link>
          {/* Progress Bar 
            This bar displays how close you are to your goal
          */}
          {isAuthenticated && (
            <div
              className='progress my-auto p-m-3'
              style={{
                width: '45%',
                justifySelf: 'center',
                alignSelf: 'center',
              }}
            >
              {loading ? (
                <div
                  className='progress-bar progress-bar-striped progress-bar-animated'
                  id='percent2'
                  role='progressbar'
                  style={{ width: `100%` }}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              ) : (
                <div
                  className='progress-bar'
                  id='percent2'
                  role='progressbar'
                  style={{
                    width: `${percentCompleted}%`,
                    background:
                      percentCompleted == 100 &&
                      'linear-gradient(to right, rgba(var(--bs-success-rgb), 0.5), rgba(var(--bs-info-rgb), 0.5), var(--bs-primary))', //, rgba(75, 0, 130, 0.5), rgba(238, 130, 238, 0.5))',
                  }}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              )}
            </div>
          )}

          {/* End of Progress Bar */}
          <Menu>
            <ul className='navbar-nav justify-content-end flex-grow-1 pe-3'>
              <li className='nav-item d-inline' style={{ display: 'inline' }}>
                {/* Home Link */}
                <NavLink
                  // className={()'btn btn-dark text-white mb-3'}
                  className={({ isActive, isPending }) => {
                    const extraClass = isActive
                      ? 'btn-dark text-white'
                      : 'btn-outline-secondary';
                    const className = `btn ${extraClass} mb-3`;
                    return className;
                  }}
                  id='home-link'
                  to='/'
                >
                  Home
                </NavLink>
                {/* End of Home Link */}
              </li>
              <li className='nav-item d-inline'>
                {/* Charts Link */}
                <div className='dropdown center'>
                  <NavLink
                    // className={()'btn btn-dark text-white mb-3'}
                    className={({ isActive, isPending }) => {
                      const extraClass = isActive
                        ? 'btn-dark text-white'
                        : 'btn-outline-secondary';
                      const className = `btn ${extraClass} mb-3 position-relative`;
                      return className;
                    }}
                    id='home-link'
                    to='/charts'
                  >
                    Charts
                  </NavLink>
                  <ul className='dropdown-menu w-70 m-auto p-auto mt-3 center-self center'>
                    <li>
                      <Link className='dropdown-item' to='Charts/per day.html'>
                        Per Day
                      </Link>
                    </li>
                    <li>
                      <Link className='dropdown-item' to='Charts/per task.html'>
                        Per Task
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* End of Charts Link */}
              </li>
            </ul>
          </Menu>
        </div>
      </nav>
      {/* End of Top Bar */}
    </>
  );
};

export default Navbar;
